var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card-text',[_c('v-autocomplete',{attrs:{"autocomplete":"off","label":_vm.$t('tag.fields.type'),"items":_vm.functionButtonTypes,"required":"","item-text":"name","item-value":"value","prepend-icon":"mdi-function-variant"},on:{"change":_vm.typeChanged},model:{value:(_vm.localModel.functionType),callback:function ($$v) {_vm.$set(_vm.localModel, "functionType", $$v)},expression:"localModel.functionType"}}),(_vm.localModel.functionType === _vm.types.SCRIPT)?_c('v-autocomplete',{attrs:{"autocomplete":"off","loading":_vm.scriptStatus.loading,"label":_vm.$t('script.name'),"items":_vm.calledScripts,"item-text":"name","item-value":"scriptId","prepend-icon":"mdi-cellphone-link","clearable":""},model:{value:(_vm.localModel.script),callback:function ($$v) {_vm.$set(_vm.localModel, "script", $$v)},expression:"localModel.script"}}):_vm._e(),_c('div',{staticClass:"ma-0 pa-0"},[(
          _vm.localModel.functionType === _vm.types.VALUEINPUT ||
          _vm.localModel.functionType === _vm.types.DOWNLINK ||
          _vm.localModel.functionType === _vm.types.ONOFFSWITCH
        )?_c('v-autocomplete',{attrs:{"autocomplete":"off","label":_vm.$t('alarm.fields.tag'),"loading":_vm.tagDataStatus.loading,"items":_vm.tags,"required":"","item-text":"name","item-value":"deveui","prepend-icon":"mdi-cellphone-link"},on:{"change":_vm.anythingChanged},model:{value:(_vm.localModel.deveui),callback:function ($$v) {_vm.$set(_vm.localModel, "deveui", $$v)},expression:"localModel.deveui"}}):_vm._e(),(
          _vm.localModel.functionType === _vm.types.VALUEINPUT ||
          _vm.localModel.functionType === _vm.types.ONOFFSWITCH
        )?_c('v-autocomplete',{attrs:{"autocomplete":"off","disabled":_vm.tagStatus.loading,"loading":_vm.tagDataStatus.loading,"label":_vm.$t('alarm.fields.tagDataKey'),"items":_vm.keys,"required":"","item-text":"label","item-value":"key","prepend-icon":"mdi-key"},model:{value:(_vm.localModel.tagKey),callback:function ($$v) {_vm.$set(_vm.localModel, "tagKey", $$v)},expression:"localModel.tagKey"}}):_vm._e()],1),(_vm.localModel.functionType === _vm.types.DASHBOARD)?_c('v-autocomplete',{attrs:{"autocomplete":"off","label":_vm.$t('dashboard.name'),"items":_vm.dashboards,"item-text":"name","item-value":"dashboardId","prepend-icon":"mdi-view-dashboard-variant","clearable":""},model:{value:(_vm.localModel.dashboard),callback:function ($$v) {_vm.$set(_vm.localModel, "dashboard", $$v)},expression:"localModel.dashboard"}}):_vm._e(),(_vm.localModel.functionType === _vm.types.DOWNLINK)?_c('div',[_c('v-text-field',{attrs:{"label":"Payload","placeholder":"Payload"},on:{"change":_vm.anythingChanged},model:{value:(_vm.localModel.downlinkPayload),callback:function ($$v) {_vm.$set(_vm.localModel, "downlinkPayload", $$v)},expression:"localModel.downlinkPayload"}}),_c('v-text-field',{attrs:{"type":"number","placeholder":"Port","label":"Port"},on:{"change":_vm.anythingChanged},model:{value:(_vm.localModel.downlinkPort),callback:function ($$v) {_vm.$set(_vm.localModel, "downlinkPort", $$v)},expression:"localModel.downlinkPort"}}),_c('v-switch',{attrs:{"label":"Confirmed"},on:{"change":_vm.anythingChanged},model:{value:(_vm.localModel.downlinkConfirmed),callback:function ($$v) {_vm.$set(_vm.localModel, "downlinkConfirmed", $$v)},expression:"localModel.downlinkConfirmed"}},[_vm._v("Confirmed")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }