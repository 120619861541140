<template>
  <v-card
    :style="
      'width: 100% !important; height: 100%; max-height: 100% !important; overflow: hidden;' +
      (soMetaBackgroundColorCard
        ? 'background-color: ' + soMetaBackgroundColorCard + ';'
        : '')
    "
    class="pb-2"
  >
    <v-toolbar dense flat style="background: transparent">
      <v-toolbar-title
        :style="soMetaTextColorTitle ? 'color: ' + soMetaTextColorTitle : ''"
        >{{ module.title }}</v-toolbar-title
      >
    </v-toolbar>

    <v-card-text class="preserve-spacing">{{ module.content }}</v-card-text>

    <v-card-text class="pt-1 pb-1" v-if="location != null">
      {{ $t("tag.fields.location") + ": " + location }}
    </v-card-text>

    <v-card-text class="pt-1 pb-1" v-if="lat != null && long != null">
      {{ "Lat: " + lat.toFixed(2) + " " + "Long: " + long.toFixed(2) }}
      <v-btn
        class="mx-2"
        x-small
        fab
        color="blue"
        target="_blank"
        :href="mapLink"
      >
        <v-icon dark> mdi-map </v-icon>
      </v-btn></v-card-text
    >

    <v-card-text class="pt-1 pb-1" v-if="latestData != null">
      {{
        $t("tagdata.latestactivity") +
        ": " +
        humanDate(latestData, "yyyy-MM-dd HH:mm:ss")
      }}</v-card-text
    >

    <v-card-text class="pt-1 pb-1" v-if="vdd != null">
      {{
        $t("tag.fields.battery") + ": " + parseFloat(vdd).toFixed(2) + "V"
      }}</v-card-text
    >

    <v-card-text class="pt-1 pb-1" v-if="rssi != null">
      RSSI: {{ rssi }}</v-card-text
    >

    <v-card-text class="pt-1 pb-1" v-for="item in data" :key="item.key">
      {{ item.key + ": " + parseFloat(item.value).toFixed(2) }}
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import Meta from "@/_helpers/ModuleMeta";

export default {
  name: "InfoCard",
  mixins: [Meta],
  props: ["module", "fakeData"],

  data() {
    return {
      search: "",
      expanded: false,
      dialog: false,
      deleting: false,
      data: [],
      vdd: null,
      lat: null,
      long: null,
      rssi: null,
      latestData: null,
      location: null,
      coords: null,
    };
  },

  computed: {
    ...mapState("tag", { tags: "tags", tagStatus: "status" }),
    ...mapState("tagData", { tagDataStatus: "status" }),

    mapLink() {
      return (
        "https://maps.google.com/?q=" +
        this.lat +
        "," +
        this.long +
        "&ll=" +
        this.lat +
        "," +
        this.long +
        "&z=12"
      );
    },
  },

  methods: {
    ...mapActions("tag", {
      getTags: "getLatestForCompany",
    }),

    async updateTagList() {
      this.data = [];
      for (let t in this.tags) {
        // Find tag in results that is matching module meta
        if (
          this.module.tag != undefined &&
          this.module.tag.deveui == this.tags[t].deveui
        ) {
          let tag = this.tags[t];
          this.lat = tag.latitude != null ? tag.latitude : null;
          this.long = tag.longitude != null ? tag.longitude : null;
          this.vdd = tag.vdd != null ? tag.vdd : null;
          this.rssi = tag.rssi != null ? tag.rssi : null;
          this.latestData = tag.lastContact;

          await this.loadLocation();

          for (let d in this.tags[t].data)
            for (let k in this.module.meta) {
              if (this.tags[t].data[d].key == this.module.meta[k].valueMatch)
                this.data.push({
                  key: this.tags[t].data[d].key,
                  value: this.tags[t].data[d].value,
                });
            }
        }
      }
    },

    async loadLocation() {
      if (this.lat && this.long) {
        if (
          !this.coords ||
          (this.coords.lat != this.lat && this.coords.long != this.long)
        ) {
          let axiosConnection = axios.create({});
          let res = await axiosConnection.post(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.lat},${this.long}&key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}`
          );

          this.location = res.data.results[0].formatted_address;
          this.coords = {};
          this.coords.lat = this.lat;
          this.coords.long = this.long;
        }
      }
    },
  },

  async mounted() {
    await this.getTags();
    await this.updateTagList();
  },

  watch: {
    timerTick() {
      this.getTags();
      this.updateTagList();
    },
  },
};
</script>

<style>
.preserve-spacing {
  white-space: pre-wrap;
}
</style>
