<template>
  <v-container fluid style="padding: 0; height: 100%">
    <v-card
      :style="
        'height: 100%; overflow: hidden; z-index: 0;' +
        (soMetaBackgroundColorCard
          ? 'background-color: ' + soMetaBackgroundColorCard
          : '')
      "
    >
      <v-row no-gutters>
        <v-toolbar
          dense
          flat
          :style="
            'background: transparent;' +
            (soMetaTextColorTitle ? 'color: ' + soMetaTextColorTitle : '')
          "
        >
          <v-toolbar-items style="flex-direction: column" class="text-left">
            <v-toolbar-title>{{ module.title }}</v-toolbar-title>
            <v-spacer />
            <span
              v-if="!fakeData && loaded"
              style="width: 100%; font-size: 0.8rem"
              >{{ humanDate(this.dataValue.createdAt) }}</span
            >
          </v-toolbar-items>
        </v-toolbar>
      </v-row>
      <v-row
        style="
          max-height: 80%;
          height: 80%;
          width: 100%;
          max-width: 100%;
          margin: 0;
        "
      >
        <e-chart
          v-if="loaded"
          :option="opts"
          :style="
            'z-index: 1;' +
            (fakeData
              ? 'width: 100%; height: 250px; overflow: hidden'
              : gauge180
              ? 'width: 100%; height: 160%; overflow: hidden'
              : 'width: 100%; height: 100%; overflow: hidden')
          "
          class="px-2"
          :autoresize="true"
          :theme="this.darkmode ? 'sensoronlinedark' : 'macarons'"
        />
      </v-row>
      <v-row no-gutters style="padding-bottom: 1rem"> </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Meta from "@/_helpers/ModuleMeta";

export default {
  name: "Gauge",
  mixins: [Meta],
  props: {
    settingsEnabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    module: {
      default: {},
    },
    tag: {
      default: null,
    },
    timerTick: {
      default: null,
    },
    fakeData: {
      default: false,
    },
  },

  data() {
    return {
      opts: {
        series: [
          {
            type: "gauge",
            center: ["50%", "60%"],
            startAngle: 200,
            endAngle: -20,
            min: 0,
            max: 60,
            z: 9999,
            itemStyle: {
              color: "#4169E1",
            },
            progress: {
              show: true,
              width: 11,
            },
            pointer: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {},
            },
            axisTick: {
              show: false,
              lineStyle: {},
            },
            splitLine: {
              show: false,
              lineStyle: {},
            },
            axisLabel: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 0,
              },
            ],
          },
          {
            type: "gauge",
            min: 0,
            max: 60,
            splitNumber: 5,
            itemStyle: {
              color: "#999",
            },
            progress: {
              show: false,
            },

            pointer: {
              show: true,
            },
            axisLine: {
              lineStyle: {
                color: ["#6a995e"],
              },
            },
            axisTick: {
              show: true,
              splitNumber: 5,
              lineStyle: {
                width: 1,
              },
            },
            splitLine: {
              length: 10,
              lineStyle: {
                width: 3,
              },
            },
            axisLabel: {
              fontSize: 13,
              formatter: function (v) {
                return +v.toFixed(2);
              },
            },
            anchor: {
              show: false,
            },
            title: {
              show: true,
            },
            detail: {
              show: true,
              valueAnimation: true,
              width: "60%",
              lineHeight: 40,
              height: "15%",
              borderRadius: 8,
              offsetCenter: [0, "60%"],
              fontSize: 35,
              fontWeight: "bolder",
              formatter: "{value}",
              color: "inherit",
            },
            data: [
              {
                value: 0,
              },
            ],
          },
        ],
      },
      loaded: false,
      defaultColor: this.darkmode ? "#FFB347" : "#449DD1",
      backgroundColor: null,
      titleTextColor: null,
      successColor: "#62B58F",
      dataValue: null,
      defaultFormatter: "{value}",
      gauge180: false,
      hasTarget: false,
    };
  },

  methods: {
    ...mapActions("tagData", { getData: "getTagDataWithMetrics" }),
    ...mapActions("alarms", ["getAlarms"]),

    setData(val) {
      let target = this.getTarget();
      if (target == undefined && this.opts.series.length > 1)
        this.opts.series.splice(0, 1);

      for (var i in this.opts.series) {
        if (i == 0 && target != undefined) {
          this.opts.series[i].data[0].value = +parseFloat(target).toFixed(2);
        } else {
          this.opts.series[i].data[0].value = +parseFloat(val).toFixed(2);
        }
      }
    },

    getTarget() {
      let value = undefined;
      for (var i in this.module.meta) {
        if (this.module.meta[i].key == "so_target") {
          this.hasTarget = true;
          return this.module.meta[i].valueMatch;
        }
      }
      return value;
    },

    getSufixPrefix() {
      let prefix = null;
      let sufix = null;
      for (var i in this.module.meta) {
        if (this.module.meta[i].key == "so_sufix") {
          sufix = this.module.meta[i].value;
        }

        if (this.module.meta[i].key == "so_prefix") {
          prefix = this.module.meta[i].value;
        }
      }
      return [prefix, sufix];
    },

    setFormatter() {
      let i = 0;
      if (this.opts.series.length > 1) {
        i = 1;
      }
      let format = "{value}";
      let preSuf = this.getSufixPrefix();
      if (preSuf[1]) {
        format = format + " " + preSuf[1];
      }
      if (preSuf[0]) {
        format = preSuf[0] + " " + format;
      }
      this.opts.series[i].detail.formatter = format;
    },

    initGauge() {
      let min = parseInt(this.module.min);
      let max = parseInt(this.module.max);
      for (var i in this.opts.series) {
        this.opts.series[i].min = min;
        this.opts.series[i].max = max;
      }

      this.setAlarmColors();
      this.setFormatter();
    },

    setAlarmColors() {
      if (
        this.fakeData ||
        this.module.meta.filter(
          (m) => m.key && m.key == "so_force_colors" && m.value == "true"
        ).length >= 1
      ) {
        this.setDefaultColors();
        return;
      }

      let i;
      if (this.opts.series.length > 1) {
        i = 1;
      } else {
        i = 0;
      }

      if (this.dataValue == null) return;

      let al = null;
      this.alarms.forEach((a) => {
        if (
          a.tagDataKey === this.dataValue.key &&
          a.tag.deveui === this.dataValue.tagDeveui &&
          a.active === true
        ) {
          al = a;
        }
      });

      if (this.alarms == null || this.dataValue == null || al == null) {
        this.setDefaultColors();
        return;
      }

      // Check the min value
      let min = parseInt(this.module.min);
      let max = parseInt(this.module.max);
      let span = 0;

      if (this.module.max < 0) {
        max = Math.abs(max);
      }

      if (this.module.min < 0) {
        min = Math.abs(min);
        span = min + max;
      } else {
        span = max - min;
      }

      // 11 - 6 = 5;  -10 + 16 = 6 / 21
      // 6|--------------------------------0-----|15
      let colors = [];
      if (al.limitLow && al.limitLow > this.module.min) {
        let v = al.limitLow - min;
        if (this.module.min < 0) {
          v = al.limitLow + min;
        }

        colors.push([Math.abs(v) / span - 0.001, "#F2726F"]);
      }

      if (al.limitHigh) {
        let v2 = al.limitHigh - min;
        if (this.module.min < 0) {
          v2 = al.limitHigh + min;
        }
        colors.push([Math.abs(v2) / span, "#62B58F"]);
      }

      colors.push([1, "#F2726F"]);
      if (this.opts.series[i])
        this.opts.series[i].axisLine.lineStyle.color = colors;
    },

    setDefaultColors() {
      let i;
      // If there are more series than 1 there is a series
      // for the target so we advance one.
      if (this.opts.series.length > 1) {
        i = 1;
      } else {
        i = 0;
      }

      // Set default color if there is no other colors
      if (this.opts.series[i] == undefined) return;
      this.opts.series[i].axisLine.lineStyle.color = [[1, "#62B58F"]];

      if (!this.module.meta) return;

      let colors = [];
      let styleMetas = this.module.meta.filter((m) => {
        if (m.key) return m.key.startsWith("so_style_meta");

        return false;
      });

      for (var j in styleMetas) {
        let style = styleMetas[j];
        // We only need to set the value max because of the way echarts work
        colors.push([style.valueMatch / 100, style.content]);
      }

      if (colors.length > 0) {
        // Sort the colors for correct viewing
        colors = colors.sort((a, b) => a[0] - b[0]);
        // Make sure the last value is extending to the end
        if (colors[colors.length - 1][0] < 1) {
          colors[colors.length - 1][0] = 1;
        }

        this.opts.series[i].axisLine.lineStyle.color = colors;
      }
    },

    setGaugeStyle() {
      if (!this.module.meta) return;

      let series = this.opts.series[0];

      if (this.getMeta(this.module.meta, "so_180_gauge")) {
        this.gauge180 = true;

        if (this.hasTarget) {
          this.set180Params(series, false);
          series = this.opts.series[1];
          this.set180Params(series, true);
        } else this.set180Params(series, true);
      } else {
        this.gauge180 = false;

        this.setStandardParams(series);
        if (this.hasTarget) {
          series = this.opts.series[1];
          this.setStandardParams(series);
        }
      }
    },

    set180Params(series, hasPointer) {
      series.startAngle = 180;
      series.endAngle = 0;
      if (hasPointer)
        series.pointer = {
          icon: "path://M12,2L4.5,20.29L5.21,21L12,18L18.79,21L19.5,20.29L12,2Z",
          length: "25%",
          width: "12%",
          offsetCenter: [0, "-40%"],
          itemStyle: {
            color: "auto",
          },
        };
      else series.pointer = { show: false };
      series.detail.offsetCenter = [0, "-5%"];
      series.axisTick.distance = 3;
      series.axisTick.lineStyle.color = "auto";
      series.splitLine.distance = 3;
      series.splitLine.lineStyle.color = "auto";
      series.axisLabel.distance = 17;
      series.axisLabel.color = "inherit";
      series.radius = "100%";
      series.axisLine.lineStyle.width = 15;
      series.center = ["50%", "50%"];
    },

    setStandardParams(series) {
      series.startAngle = 200;
      series.endAngle = -20;
      series.pointer.icon = undefined;
      series.detail.offsetCenter = [0, "60%"];
      series.axisTick.distance = -30;
      series.axisTick.lineStyle.color = "#999";
      series.splitLine.distance = -48;
      series.splitLine.lineStyle.color = "#999";
      series.axisLabel.distance = -10;
      series.axisLabel.color = "#999";
      series.radius = "75%";
      series.axisLine.lineStyle.width = 18;
      series.center = ["50%", "60%"];
    },

    async updateData() {
      let data = await this.getData({
        metrics: {
          tag: this.module.tag.deveui,
          key: this.module.key,
          limit: 1,
        },
      });

      if (data != undefined && data.length > 0) {
        this.dataValue = data[0];
        this.setData(this.dataValue.value);
      } else {
        this.setData(0);
      }
    },
  },

  computed: {
    ...mapState("configuration", ["darkmode"]),
    ...mapState("alarms", ["alarms"]),
  },

  async created() {
    if (!this.fakeData) {
      await this.getAlarms();
      let data = await this.getData({
        metrics: {
          tag: this.module.tag.deveui,
          key: this.module.key,
          limit: 1,
        },
      });

      if (data != undefined && data.length > 0) {
        this.dataValue = data[0];
        this.setData(this.dataValue.value);
      } else {
        this.dataValue = 0;
        this.setData(0);
      }
    } else {
      // Fake part for preview
      this.setData(35);
      this.module.min = 0;
      this.module.max = 45;
    }

    this.initGauge();
    this.setGaugeStyle();
    this.loaded = true;
  },

  watch: {
    timerTick() {
      this.updateData();
    },

    module(v1, v2) {
      if (v1.meta != v2.meta) {
        this.setAlarmColors();
        this.setGaugeStyle();
      }
    },
  },
};
</script>