var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"d-none d-md-block preview-container elevation-2",attrs:{"fluid":""}},[_c('span',{staticStyle:{"display":"fixed","font-size":"1.6rem"}},[_vm._v(_vm._s(_vm.$t("common.preview")))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":12,"md":12}},[(_vm.type == _vm.ModuleTypes.CHART || _vm.type == _vm.ModuleTypes.MULTICHART)?_c('ChartPreview',{attrs:{"fakeData":true,"module":{ title: _vm.title, meta: _vm.meta },"settingsEnabled":false}}):_vm._e(),(_vm.type == _vm.ModuleTypes.TRENDCHART)?_c('TrendChartPreview',{attrs:{"fakeData":true,"module":{ title: _vm.title, meta: _vm.meta, tag: _vm.previewTag, key: _vm.tagKey }}}):_vm._e(),(_vm.type == _vm.ModuleTypes.GAUGE)?_c('GaugePreview',{attrs:{"module":{ title: _vm.title, meta: _vm.meta },"fakeData":true,"settingsEnabled":false}}):_vm._e(),(_vm.type == _vm.ModuleTypes.SINGLE)?_c('ModuleSingle',{attrs:{"module":{ title: _vm.title, meta: _vm.meta },"fakeData":true}}):_vm._e(),(_vm.type == _vm.ModuleTypes.TEXT)?_c('TextCard',{attrs:{"label":_vm.title,"value":_vm.content,"module":{ meta: _vm.meta },"loading":false}}):_vm._e(),(_vm.type == _vm.ModuleTypes.MAP)?_c('TagMap'):_vm._e(),(_vm.type == _vm.ModuleTypes.IMAGEMAP)?_c('ModImageMap',{attrs:{"module":{ title: _vm.title, meta: _vm.meta },"fakeData":true}}):_vm._e(),(_vm.type == _vm.ModuleTypes.IFRAME)?_c('ModIFrame',{attrs:{"module":{ title: _vm.title, meta: _vm.meta },"fakeData":true,"src":"https://sensor-online.se"}}):_vm._e(),(_vm.type == _vm.ModuleTypes.IMAGE)?_c('v-img',{attrs:{"src":"missing-img.png"}}):_vm._e(),(_vm.type == _vm.ModuleTypes.WEATHER)?_c('ModSMHI',{attrs:{"module":{
          tag: {
            name: 'Sensor Skövde',
            latitude: '58.39027820',
            longitude: '13.84612080',
          },
          meta: _vm.meta,
        },"fakeData":true}}):_vm._e(),(_vm.type == _vm.ModuleTypes.RAWTABLE && _vm.deveui != '')?_c('RawTable',{attrs:{"module":{
          tag: { name: 'RawTable Preview', deveui: _vm.deveui },
          meta: _vm.meta,
        },"fakeData":true}}):_vm._e(),(_vm.type == _vm.ModuleTypes.FUNCTIONBUTTON)?_c('ModFunctionButton',{attrs:{"module":{ title: _vm.title, meta: _vm.meta },"fakeData":true}}):_vm._e(),(_vm.type == _vm.ModuleTypes.SENSOROVERVIEW)?_c('SensorOverviewList',{attrs:{"module":{ title: _vm.title, meta: _vm.meta },"fakeData":true}}):_vm._e(),(_vm.type == _vm.ModuleTypes.TILTCHART)?_c('TiltChart',{attrs:{"module":{
          title: _vm.title,
          tag: { name: _vm.$t('common.sensorName') },
          meta: _vm.meta,
        },"fakeData":true}}):_vm._e(),(_vm.type == _vm.ModuleTypes.INFOCARD && _vm.deveui != '')?_c('InfoCard',{attrs:{"module":{
          title: _vm.title,
          content: _vm.content,
          tag: { name: _vm.$t('common.sensorName'), deveui: _vm.deveui },
          meta: _vm.meta,
        },"fakeData":true}}):_vm._e(),(_vm.type == _vm.ModuleTypes.FILLINDICATOR)?_c('FillIndicator',{attrs:{"fakeData":true,"module":{ title: _vm.title, meta: _vm.meta }}}):_vm._e(),(_vm.type == _vm.ModuleTypes.CONTROLSLIDERS)?_c('ControlSliders',{attrs:{"fakeData":true,"module":{ title: _vm.title, meta: _vm.meta }}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }