<template>
  <v-container fluid>
    <div>
      <v-icon
        class="pa-3"
        x-large
        v-for="key in mdiIconsKeysPaged"
        :key="key"
        @click="setIcon(key)"
        :color="localValue === key ? 'primary' : ''"
        >mdi-{{ key }}</v-icon
      >
    </div>
    <v-pagination
      v-model="page"
      :length="Math.ceil(mdiIconsKeys.length / pageSize)"
    />
  </v-container>
</template>

<script>
import iconMixin from "../../../_helpers/iconMixin";
export default {
  name: "DbMapIconSelector",

  mixins: [iconMixin],

  props: {
    value: {
      default: "help",
    },
  },

  model: { prop: "value" },

  data() {
    return { page: 1, pageSize: 25 };
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
        this.$emit("change");
      },
    },

    mdiIconsKeysPaged() {
      // Pagination in vuetify starts at 1 instead of 0
      let pg = this.page - 1;
      return this.mdiIconsKeys.slice(
        pg * this.pageSize,
        (pg + 1) * this.pageSize
      );
    },
  },

  methods: {
    setIcon(val) {
      this.localValue = val;
    },
  },
};
</script>