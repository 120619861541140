var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","height":"100%"}},[_c('div',{staticClass:"d-flex flex-column justify-start align-start pa-2 fill-height"},[(_vm.isForm)?_c('div',{staticClass:"pa-2",staticStyle:{"width":"100%"}},[_c('v-form',{ref:"form",staticClass:"d-flex justify-space-between align-center",attrs:{"lazy-validation":true},on:{"submit":function($event){$event.preventDefault();return _vm.saveDashboard.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-pen","label":_vm.$t('dashboard.fields.name'),"name":"dashboardName","flat":"","dense":"","rules":[
            (v) => !!v || this.$t('alarm.validation.nameRequired'),
            (v) => (v && v.length <= 255) || this.$t('alarm.validation.max'),
          ]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"type":"submit","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("common.save"))+" ")])],1)],1):_vm._e(),(_vm.positions.length > 0)?_c('v-slider',{staticStyle:{"width":"100%"},attrs:{"min":1,"max":_vm.positions.length,"label":_vm.$t('map.stepPositions')},model:{value:(_vm.positionSliceIndex),callback:function ($$v) {_vm.positionSliceIndex=$$v},expression:"positionSliceIndex"}}):_vm._e(),_c('gmap-map',{ref:"gmapRef",staticStyle:{"height":"100%","width":"100%","min-height":"100%"},attrs:{"zoom":7,"center":_vm.center},on:{"click":_vm.mapClicked,"rightclick":_vm.mapRightClicked}},[(_vm.positions.length > 0)?_c('gmap-polyline',{attrs:{"path":_vm.positions.slice(0, _vm.positionSliceIndex),"editable":false,"options":{
          strokeColor: '#2196F3',
          strokeWeight: '3',
          zIndex: 1,
        }}}):_vm._e(),(_vm.positions.length > 0)?_c('gmap-polyline',{attrs:{"path":_vm.positions.slice(0, _vm.positionSliceIndex),"editable":false,"options":{
          strokeColor: 'black',
          strokeWeight: '4',
          zIndex: 0,
        }}}):_vm._e(),_vm._l((_vm.positionMarkers),function(position,i){return _c('db-map-marker',{key:'pos-' + i,attrs:{"position":position,"label":_vm.humanDate(position.createdAt),"latitude":position.lat,"longitude":position.lng,"tag-id":position.deveui,"position-marker":"","z-index":2}})}),_vm._l((_vm.markers),function(v,i){return _c('db-map-marker',{key:'marker-' + _vm.k + '-' + i,attrs:{"value":v.iconStyle,"preview":_vm.isForm,"latitude":v.latitude,"longitude":v.longitude},on:{"click":(e) => _vm.markerClicked(e, v),"contextmenu":(e) => _vm.contextMenuMarkerClicked(e, v)}})})],2),(_vm.selectedPos != undefined)?_c('db-map-form',{attrs:{"position":_vm.selectedPos,"marker":_vm.contextMenu?.marker},on:{"submit":_vm.dbMapFormSubmit,"abort":_vm.abortForm},model:{value:(_vm.formDialog),callback:function ($$v) {_vm.formDialog=$$v},expression:"formDialog"}}):_vm._e()],1),(_vm.contextMenu !== undefined && _vm.permitted('Dashboard.Update'))?_c('div',{staticClass:"context-menu",style:(`top: ${_vm.contextMenu.position.y}px; left: ${_vm.contextMenu.position.x}px`)},[(_vm.contextMenu.marker != undefined)?_c('div',[(_vm.permitted('Dashboard.Update'))?_c('div',{staticClass:"context-menu-item",on:{"click":function($event){return _vm.openEditMarker(_vm.contextMenu.marker)}}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-pen")]),_vm._v(" "+_vm._s(_vm.$t("common.edit"))+" ")],1):_vm._e(),(
          _vm.permitted('Dashboard.View') && _vm.contextMenu.marker.useTagPosition
        )?_c('div',{staticClass:"context-menu-item",on:{"click":() => {
            this.positionFormDialog = true;
          }}},[_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("mdi-map")]),_vm._v(" "+_vm._s(_vm.$t("map.displayHistory"))+" ")],1):_vm._e(),(_vm.permitted('Dashboard.View') && _vm.positions.length > 0)?_c('div',{staticClass:"context-menu-item",on:{"click":_vm.clearPositions}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(_vm.$t("map.clearPositions"))+" ")],1):_vm._e(),(_vm.permitted('Dashboard.Delete'))?_c('div',{staticClass:"context-menu-item",on:{"click":function($event){_vm.confirmDelete = true}}},[_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("mdi-delete")]),_vm._v(" "+_vm._s(_vm.$t("common.delete"))+" ")],1):_vm._e()]):_c('div',[(_vm.permitted('Dashboard.Create'))?_c('div',{staticClass:"context-menu-item",on:{"click":function($event){return _vm.formOpen(_vm.contextMenu.event)}}},[_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("common.create"))+" ")],1):_vm._e(),(_vm.permitted('Dashboard.View') && _vm.positions.length > 0)?_c('div',{staticClass:"context-menu-item",on:{"click":_vm.clearPositions}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(_vm.$t("map.clearPositions"))+" ")],1):_vm._e()])]):_vm._e(),_c('delete-dialog',{attrs:{"dialog":_vm.confirmDelete},on:{"confirm-delete":function($event){return _vm.removeMarker(_vm.contextMenu.marker)},"cancel-dialog":function($event){_vm.confirmDelete = false}}}),(_vm.tag && _vm.templateDashboardId)?_c('div',{staticClass:"db-map-side-dashboard",style:(_vm.dashboardContainerType(1))},[_c('template-dashboard-container',{attrs:{"tags":[_vm.tag],"template-dashboard-id":_vm.templateDashboardId,"dashboard-type":1},on:{"close":() => {
          _vm.tag = undefined;
          _vm.templateDashboardId = undefined;
        }}})],1):_vm._e(),_c('so-dialog',{attrs:{"width":700},on:{"accept":_vm.fetchAndSetPositions},model:{value:(_vm.positionFormDialog),callback:function ($$v) {_vm.positionFormDialog=$$v},expression:"positionFormDialog"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("map.dateTimeSpanSelection")))]),_c('v-card-text',[_c('h3',[_vm._v(_vm._s(_vm.$t("map.dateFrom")))]),_c('so-date-time-picker',{model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}})],1),_c('v-card-text',[_c('h3',[_vm._v(_vm._s(_vm.$t("map.dateTo")))]),_c('so-date-time-picker',{model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }