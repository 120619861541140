<template>
  <div>
    <v-card-text>
      <v-autocomplete
        autocomplete="off"
        :label="$t('tag.fields.type')"
        v-model="localModel.functionType"
        :items="functionButtonTypes"
        required
        item-text="name"
        item-value="value"
        prepend-icon="mdi-function-variant"
        @change="typeChanged"
      ></v-autocomplete>

      <v-autocomplete
        v-if="localModel.functionType === types.SCRIPT"
        autocomplete="off"
        :loading="scriptStatus.loading"
        :label="$t('script.name')"
        v-model="localModel.script"
        :items="calledScripts"
        item-text="name"
        item-value="scriptId"
        prepend-icon="mdi-cellphone-link"
        clearable
      ></v-autocomplete>

      <div class="ma-0 pa-0">
        <v-autocomplete
          autocomplete="off"
          :label="$t('alarm.fields.tag')"
          :loading="tagDataStatus.loading"
          v-model="localModel.deveui"
          @change="anythingChanged"
          :items="tags"
          required
          item-text="name"
          item-value="deveui"
          prepend-icon="mdi-cellphone-link"
          v-if="
            localModel.functionType === types.VALUEINPUT ||
            localModel.functionType === types.DOWNLINK ||
            localModel.functionType === types.ONOFFSWITCH
          "
        ></v-autocomplete>

        <v-autocomplete
          autocomplete="off"
          :disabled="tagStatus.loading"
          :loading="tagDataStatus.loading"
          :label="$t('alarm.fields.tagDataKey')"
          v-model="localModel.tagKey"
          :items="keys"
          required
          item-text="label"
          item-value="key"
          prepend-icon="mdi-key"
          v-if="
            localModel.functionType === types.VALUEINPUT ||
            localModel.functionType === types.ONOFFSWITCH
          "
        ></v-autocomplete>
      </div>

      <v-autocomplete
        v-if="localModel.functionType === types.DASHBOARD"
        autocomplete="off"
        :label="$t('dashboard.name')"
        v-model="localModel.dashboard"
        :items="dashboards"
        item-text="name"
        item-value="dashboardId"
        prepend-icon="mdi-view-dashboard-variant"
        clearable
      ></v-autocomplete>

      <div v-if="localModel.functionType === types.DOWNLINK">
        <v-text-field
          label="Payload"
          placeholder="Payload"
          v-model="localModel.downlinkPayload"
          @change="anythingChanged"
        ></v-text-field>

        <v-text-field
          type="number"
          placeholder="Port"
          label="Port"
          v-model="localModel.downlinkPort"
          @change="anythingChanged"
        ></v-text-field>
        <v-switch
          v-model="localModel.downlinkConfirmed"
          @change="anythingChanged"
          label="Confirmed"
          >Confirmed</v-switch
        >
      </div>
    </v-card-text>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n.js";
import CsharpEnum from "@/_helpers/CsharpEnum";
import Meta from "@/_helpers/ModuleMeta";
import { mapActions, mapState } from "vuex";

export default {
  name: "FunctionButtonForm",

  props: {
    module: { default: {}, require: true },
    model: {
      default: {},
      required: true,
    },
  },

  model: {
    prop: "model",
    event: "model-update",
  },

  data() {
    return {
      functionButtonTypes: [
        {
          name: i18n.t("module.functionButtonTypes.script"),
          value: CsharpEnum.FunctionTypes.SCRIPT,
        },
        {
          name: i18n.t("module.functionButtonTypes.input"),
          value: CsharpEnum.FunctionTypes.VALUEINPUT,
        },
        {
          name: i18n.t("module.functionButtonTypes.navigation"),
          value: CsharpEnum.FunctionTypes.DASHBOARD,
        },
        {
          name: i18n.t("module.functionButtonTypes.downlink"),
          value: CsharpEnum.FunctionTypes.DOWNLINK,
        },
        {
          name: i18n.t("module.functionButtonTypes.onoffSwitch"),
          value: CsharpEnum.FunctionTypes.ONOFFSWITCH,
        },
      ],
      typeMetas: [
        "so_script_id",
        "so_dashboard_id",
        Meta.Enum.DOWNLINK,
        Meta.Enum.ONOFFSWITCH,
      ],
    };
  },

  async created() {
    await this.setupForm();
  },

  methods: {
    ...mapActions("scripts", ["getScripts", "setCurrentScript"]),
    ...mapActions("dashboards", ["getDashboards"]),
    ...mapActions("tag", ["getTags", "getKeysForTag"]),

    async setupForm() {
      if (
        this.localModel == undefined ||
        Object.keys(this.localModel).length <= 0
      ) {
        this.localModel = {
          deveui: "",
          functionType: this.types.SCRIPT,
          script: null,
          dashboard: null,
          tagKey: "",
          downlinkPayload: "",
          downlinkPort: 1,
          downlinkConfirmed: false,
        };
        await this.getScripts();
      } else {
        this.localModel.deveui = this.module.tag ? this.module.tag.deveui : "";
        this.localModel.tagKey = this.module.key;
      }

      var isInputValue = true;
      if (this.module.meta) {
        for (let meta of this.module.meta) {
          if (meta.key == "so_script_id") {
            this.localModel.functionType = this.types.SCRIPT;
            this.localModel.script = parseInt(meta.value);
            isInputValue = false;
            await this.getScripts();
            break;
          }

          if (meta.key == "so_dashboard_id") {
            this.localModel.functionType = this.types.DASHBOARD;
            this.localModel.dashboard = parseInt(meta.value);
            isInputValue = false;
            await this.getDashboards();
            break;
          }

          if (meta.key == Meta.Enum.DOWNLINK) {
            this.localModel.functionType = this.types.DOWNLINK;
            this.localModel.downlinkPayload = meta.value;
            this.localModel.downlinkPort = meta.valueMatch;
            this.localModel.downlinkConfirmed =
              meta.content === "true" ? true : false;
            isInputValue = false;
            await this.getTags();
            break;
          }

          if (meta.key == Meta.Enum.ONOFFSWITCH) {
            this.localModel.functionType = this.types.ONOFFSWITCH;
            isInputValue = false;
            break;
          }
        }
      }

      if (isInputValue) {
        this.localModel.functionType = this.types.VALUEINPUT;
      }
    },

    anythingChanged() {
      this.$emit("model-update", this.localModel);
    },

    async typeChanged(v) {
      if (this.module.meta)
        this.module.meta = this.module.meta.filter(
          (m) => !this.typeMetas.includes(m.key)
        );

      this.anythingChanged();

      if (v == this.types.SCRIPT) await this.getScripts();
      else if (v == this.types.DASHBOARD) await this.getDashboards();
      else if (v == this.types.DOWNLINK) await this.getTags();
    },
  },

  computed: {
    ...mapState("scripts", { scriptStatus: "status", scripts: "scripts" }),
    ...mapState("dashboards", ["dashboards", "currentDashboard"]),
    ...mapState("tag", { tagStatus: "status", tags: "tags" }),
    ...mapState("tagData", { tagDataStatus: "status", keys: "keys" }),

    calledScripts() {
      return this.scripts.filter((s) => {
        return s.type == 1;
      });
    },

    localModel: {
      get: function () {
        return this.model;
      },

      set: function (v) {
        this.$emit("model-update", v);
      },
    },

    deveui() {
      if (this.localModel.deveui != undefined) return this.localModel.deveui;

      return null;
    },

    types() {
      return CsharpEnum.FunctionTypes;
    },
  },

  watch: {
    async deveui(val, oldVal) {
      if (val && val != oldVal) await this.getKeysForTag({ tagId: val });
    },

    async module() {
      await this.setupForm();
    },
  },
};
</script>

<style></style>
