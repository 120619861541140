<template>
  <gmap-marker
    v-if="icon !== undefined"
    class="m-dropplet"
    :position="position"
    :icon="icon"
    :label="localLabel"
    :options="{
      zIndex: zIndex,
    }"
    @mouseover="() => setLabel()"
    @mouseout="localLabel = undefined"
    @click="(e) => $emit('click', e)"
    @rightclick="
      (e) => {
        $emit('contextmenu', e);
      }
    "
  />
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import iconMixin from "../../../_helpers/iconMixin";

export default {
  name: "DbMapMarker",

  mixins: [iconMixin],

  props: {
    value: {
      default: "{}",
    },

    tagId: {
      type: String,
      default: undefined,
    },

    templateDashboardId: {
      type: String,
      default: undefined,
    },

    preview: {
      type: Boolean,
      default: false,
    },

    latitude: {
      type: Number,
      default: undefined,
    },

    longitude: {
      type: Number,
      default: undefined,
    },

    positionMarker: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: "",
    },

    zIndex: {
      type: Number,
      default: 99,
    },
  },

  model: { prop: "value" },

  data() {
    return {
      icon: undefined,
      localLabel: undefined,
    };
  },

  computed: {
    api: gmapApi,

    position() {
      if (
        this.latitude === undefined ||
        typeof this.latitude !== "number" ||
        this.longitude === undefined ||
        typeof this.longitude !== "number"
      )
        return this.previewPosition;

      return {
        lat: this.latitude,
        lng: this.longitude,
      };
    },

    previewPosition() {
      return {
        lat: 58.39118,
        lng: 13.84506,
      };
    },

    localValue: {
      get() {
        if (this.positionMarker) {
          return {
            iconUrl: this.cf_iconUrl(
              "position-icon",
              "#2196F3",
              "white",
              this.ICONTYPE.POSITION_ICON
            ),
            size: 10,
            origin: -120,
          };
        }

        try {
          if (this.value == undefined || this.value == "")
            return {
              iconUrl: this.cf_iconUrl(this.defaultMdiIconKey),
              size: 30,
            };

          let parsed = JSON.parse(this.value);

          // Validate that the parsed contains size and iconKey otherwise set to defaults
          if (parsed?.size === undefined || parsed.size <= 0) parsed.size = 30;
          if (parsed?.iconUrl === undefined || parsed.iconUrl === "")
            parsed.iconUrl = this.cf_iconUrl(this.defaultMdiIconKey);

          return parsed;
        } catch {
          console.warn("Could not parse the Icon payload, got: ", this.value);
          return {
            iconUrl: this.cf_iconUrl(this.defaultMdiIconKey),
            size: 30,
          };
        }
      },
    },
  },

  methods: {
    redraw() {
      this.setIcon();
    },

    setIcon() {
      this.icon = undefined;

      this.$nextTick(async () => {
        // Await gmap promise
        await this.$gmapApiPromiseLazy();

        let size = this.localValue.size;
        this.icon = {
          url: this.localValue.iconUrl,
          size: new this.api.maps.Size(size, size),
          scaledSize: new this.api.maps.Size(size, size),
          labelOrigin: new this.api.maps.Point(size / 2, -30),
        };

        // FIXME: This anchor might be the case that we only want on position markers
        // if (this.positionMarker)
        this.icon.anchor = new this.api.maps.Point(size / 2, size / 2);
      });
    },

    setLabel() {
      if (this.positionMarker) {
        this.localLabel = {
          text: this.label,
          className: "m-lbl",
        };
        return;
      }
      // If no tagId is present we can't fetch the label for the tag
      if (this.tagId == undefined) return;

      // Fetch the name from the tag and set it as the label text
      this.localLabel = {
        text: "TODO: Fetch label for tag",
        className: "m-lbl",
      };
    },
  },

  mounted() {
    this.redraw();
  },
};
</script>

<style>
.m-lbl {
  color: white !important;
  background: #383838;
  width: 100%;
  padding: 0.5rem;
  border-radius: 5px;
  position: relative;
}

.m-lbl::after {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #383838;
  position: absolute;
  content: "";
  top: 100%;
  left: calc(50% - 10px);
}
</style>