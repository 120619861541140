var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"hide-scrollbar",style:('width: 100% !important; height: 100% !important; max-height: 100%; overflow-y: scroll; overflow: hidden;' +
    (_vm.soMetaBackgroundColorCard
      ? 'background-color: ' + _vm.soMetaBackgroundColorCard
      : '')),attrs:{"no-gutter":""}},[_c('v-toolbar',{staticStyle:{"background":"transparent"},attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',{style:(_vm.soMetaTextColorTitle ? 'color: ' + _vm.soMetaTextColorTitle : '')},[_vm._v(_vm._s(_vm.module.title))])],1),_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"overflow-y":"auto","padding-bottom":"3rem","background":"transparent"},attrs:{"headers":_vm.headers,"items":_vm.tags,"items-per-page":10,"single-expand":true,"item-key":"deveui","dense":""},on:{"click:row":(row, slot) => _vm.getCurrents(slot)},scopedSlots:_vm._u([{key:`item.active`,fn:function({ item }){return [_c('v-icon',{attrs:{"dark":"","color":_vm.getIconColor(_vm.hasRecentData(item))}},[_vm._v(_vm._s(_vm.hasRecentData(item)))])]}},{key:`item.battery`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.batteryLevel(item))+" ")]}},{key:`item.rssi`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getRSSI(item))+" ")]}},{key:`item.alarm`,fn:function({ item }){return [_c('v-icon',{attrs:{"dark":"","color":_vm.alarmActive(item)}},[_vm._v("timer")])]}},{key:`item.createdAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.lastDataTime(item))+" ")]}},{key:"expanded-item",fn:function(){return [_c('td',{staticClass:"px-0",attrs:{"colspan":_vm.headers.length + 1}},[_c('v-data-table',{attrs:{"headers":[
            {},
            { text: _vm.$t('tagdata.fields.key'), value: 'label' },
            { text: _vm.$t('tag.fields.latestValue'), value: 'value' },
          ],"items":_vm.currents,"item-key":"key","hide-default-footer":"","disable-sort":"","dense":"","disable-pagination":""},on:{"click:row":_vm.clickedRow}})],1)]},proxy:true}],null,true)}),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('TagHistoryDialog',{attrs:{"dialogTrigger":_vm.dialogTrigger,"dialog":_vm.dialog},on:{"dialog-toggle":function($event){_vm.dialog = !_vm.dialog}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }