import { render, staticRenderFns } from "./ModIFrame.vue?vue&type=template&id=beba0a9a"
import script from "./ModIFrame.vue?vue&type=script&lang=js"
export * from "./ModIFrame.vue?vue&type=script&lang=js"
import style0 from "./ModIFrame.vue?vue&type=style&index=0&id=beba0a9a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports