<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("common.delete") }}</span>
        </v-card-title>
        <v-card-text>
          {{ $t("common.verify") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel()">
            {{ $t("common.cancel") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="confirmDelete()">
            {{ $t("common.delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "DeleteDialog",

  props: ["dialog"],

  methods: {
    confirmDelete() {
      this.$emit("confirm-delete");
    },

    cancel() {
      this.$emit("cancel-dialog");
    },
  },
};
</script>