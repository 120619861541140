<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="12" md="12" class="text-center">
          <v-color-picker v-model="content" style="margin: 0 auto" />
          <v-btn
            class="mx-2"
            style="margin: 0 auto"
            color="accent"
            @click="saveColor"
            >{{ $t("common.saveColor") }}</v-btn
          >
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <h3 v-if="isInType(type, [ModuleTypes.GAUGE])">
            {{ $t("common.alarmColorPrecedence") }}
          </h3>
          <v-container>
            <palette-chip
              v-for="(c, i) in lmetas"
              v-bind:key="i"
              :color="c.content"
              :metas="metas"
              :type="type"
              :meta="c"
              :localMeta="lmetas"
              :fieldNames="fieldNames"
              :colorFields="colorFields"
              class="ma-2"
              v-on:update="(m) => updateMeta(i, m)"
              v-on:remove="removeFromPalette(i)"
              :hasModuleStyle="hasModuleStyle"
            >
            </palette-chip>
          </v-container>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ModuleTypesMixin from "@/_helpers/ModuleTypeHelper.js";
import PaletteChip from "@/components/common/PaletteChip";

export default {
  name: "StylePickerModule",
  mixins: [ModuleTypesMixin],
  props: {
    type: {
      default: null,
    },
    metas: {
      default: [],
    },
  },

  data() {
    return {
      content: "#FEFEFE",
      value: "",
      valueMatch: "",
      localMeta: [],
      fieldNames: [],
      colorFields: [],
      backgroundColor: null,
    };
  },

  computed: {
    ...mapState("tag", ["tags"]),

    lmetas() {
      return this.localMeta;
    },

    hasModuleStyle() {
      let style = ModuleTypesMixin.types.find((e) => e.value == this.type);

      return style ? style.hasOwnStyle : false;
    },
  },

  methods: {
    ...mapActions("tag", ["getTags"]),

    constructAvaliableFieldNames() {
      let fieldNames = [];
      if (this.isInType(this.type, [this.ModuleTypes.MULTICHART])) {
        for (var i in this.metas) {
          // Find the tag of this
          if (!this.metas[i].value && !this.metas[i].valueMatch) continue;

          for (var j in this.tags) {
            if (this.metas[i].value == this.tags[j].deveui) {
              let n = `${this.tags[j].name} - ${this.metas[i].valueMatch}`;
              fieldNames.push({
                name: n,
                deveui: this.tags[j].deveui,
                key: this.metas[i].valueMatch,
                disabled:
                  this.localMeta.length > 0 &&
                  this.localMeta.filter((lm) => lm.value == n).length <= 0
                    ? false
                    : true,
              });
            }
          }
        }
      } else if (this.isInType(this.type, [this.ModuleTypes.TEXT])) {
        for (var i in this.TextStyleFieldNames) {
          fieldNames.push({
            name: this.TextStyleFieldNames[i].name,
            value: this.TextStyleFieldNames[i].value,
            disabled:
              this.localMeta.length > 0 &&
              this.localMeta.filter(
                (lm) => lm.value == this.TextStyleFieldNames[i].value
              ).length <= 0
                ? false
                : true,
          });
        }
      } else if (this.isInType(this.type, [this.ModuleTypes.SINGLE])) {
        for (var i in this.SingleStyleFieldNames) {
          fieldNames.push({
            name: this.SingleStyleFieldNames[i].name,
            value: this.SingleStyleFieldNames[i].value,
            disabled:
              this.localMeta.length > 0 &&
              this.localMeta.filter(
                (lm) => lm.value == this.SingleStyleFieldNames[i].value
              ).length <= 0
                ? false
                : true,
          });
        }
      }

      return fieldNames;
    },

    constructAvailableColorFields() {
      this.colorFields = [];
      let moduleType = ModuleTypesMixin.types.find((e) => e.value == this.type);

      if (!moduleType) return;

      for (let i = 0; i < ModuleTypesMixin.colorFieldsMeta.length; i++) {
        if (moduleType.colorFieldsUsed[i]) {
          let field = ModuleTypesMixin.colorFieldsMeta[i];
          field.disabled =
            this.localMeta.length > 0 &&
            this.localMeta.filter((lm) => lm.key == field.value).length > 0
              ? true
              : false;

          this.colorFields.push(field);
        }
      }
    },

    saveColor() {
      // Force the range picker to have one value atleast
      if (
        this.isInType(this.type, [
          this.ModuleTypes.GAUGE,
          this.ModuleTypes.FILLINDICATOR,
        ])
      ) {
        this.value = 100;
        this.valueMatch = 100;
      }

      this.localMeta.push({
        key: "so_style_meta_color",
        content: this.content,
        value: this.value,
        valueMatch: this.valueMatch,
      });

      this.fieldNames = this.constructAvaliableFieldNames();
      this.$emit("update", this.localMeta);
    },

    updateMeta(i, meta) {
      this.localMeta[i] = meta;
      this.fieldNames = this.constructAvaliableFieldNames();
      this.constructAvailableColorFields();
      this.$emit("update", this.localMeta);
    },

    removeFromPalette(index) {
      this.localMeta.splice(index, 1);
      this.fieldNames = this.constructAvaliableFieldNames();
      this.constructAvailableColorFields();
      this.$emit("update", this.localMeta);
    },
  },

  async created() {
    this.getTags();
    this.constructAvailableColorFields();

    if (this.metas.length > 0) {
      this.localMeta = this.metas.filter(
        (m) => m.key != null && m.key.startsWith("so_style_meta")
      );

      this.fieldNames = this.constructAvaliableFieldNames();
    }
  },

  components: {
    PaletteChip,
  },

  watch: {
    metas() {
      this.fieldNames = this.constructAvaliableFieldNames();
    },
  },
};
</script>

<style>
.theme--dark.v-color-picker input {
  color: #fff !important;
}
</style>