<template>
  <v-card
    :style="
      'width: 100% !important; height: 100% !important; overflow: auto' +
      backgroundColor +
      ignoreContentStyle
    "
  >
    <v-toolbar
      dense
      flat
      :style="titleBackground + textColor + ignoreContentTitleStyle"
    >
      <v-toolbar-title v-if="!loading">{{ label }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text
      class="text-left table-borders"
      :style="textColor"
      v-html="value"
      v-if="!loading && !ignoreContent"
    ></v-card-text>
    <template v-if="loading">
      <v-card-text>
        <v-progress-linear indeterminate color="accent"></v-progress-linear>
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
export default {
  name: "TextCard",
  props: ["label", "value", "loading", "module"],
  data() {
    return {
      ignoreContent: false,
      ignoreContentStyle: "",
      ignoreContentTitleStyle: "",
      backgroundColor: "",
      titleBackground: "",
      textColor: "",
    };
  },

  methods: {
    setStyles() {
      if (this.module.meta && this.module.meta.length > 0) {
        // Loop through the meta to check for style meta that will be set
        for (var i in this.module.meta) {
          var currentMeta = this.module.meta[i];
          if (currentMeta.key == "so_style_meta_text_background")
            this.backgroundColor = ";background-color: " + currentMeta.content;

          if (currentMeta.key == "so_style_meta_text_textcolor")
            this.textColor = ";color: " + currentMeta.content;

          if (currentMeta.key == "so_style_meta_text_title_background")
            this.titleBackground = ";background-color: " + currentMeta.content;

          if (currentMeta.key == "so_ignore_body") {
            this.ignoreContent = true;
            this.ignoreContentStyle =
              ";background-color: transparent; box-shadow: none";
          }
        }
      }
    },
  },

  watch: {
    module(val, oldVal) {
      if (val != oldVal) {
        this.setStyles();
      }
    },
  },

  created() {
    this.setStyles();
  },
};
</script>

<style>
.table-borders > table td,
.table-borders > table th {
  min-width: 4em;
  border: 1px solid #ddd;
  padding: 3px 5px;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.table-borders > pre {
  padding: 0.7rem 1rem;
  border-radius: 5px;
  background-color: rgb(47, 46, 44);
  font-size: 1rem;
}
</style>
