<template>
  <v-container>
    <v-row>
      <v-col
        :cols="12"
        :md="8"
        :sm="12"
        class="d-flex justify-left align-center"
      >
        <h2>{{ title }}</h2>
      </v-col>
      <v-col :cols="12" :md="4" :sm="12">
        <v-text-field
          v-model="search"
          :placeholder="$t('tag.search')"
          append-icon="search"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col :cols="12" :md="3" :sm="6" v-for="v in paged" :key="v[itemKey]">
        <selection-sheet
          v-model="localValue"
          :item="v"
          color="grey"
          :item-key="itemKey"
          :item-label="itemLabel"
          font-size=".6rem"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col :cols="4" class="d-flex justify-center align-center">
        <v-btn outlined @click="page = page - 1" :disabled="page <= 0"
          ><v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>

      <v-col :cols="4" class="d-flex justify-center align-center">
        <h4>{{ page + 1 }}</h4>
      </v-col>

      <v-col :cols="4" class="d-flex justify-center align-center">
        <v-btn
          outlined
          @click="page = page + 1"
          :disabled="(page + 1) * pageSize >= filtered.length"
          ><v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SelectionSheet from "./SelectionSheet.vue";
export default {
  name: "GenericSelector",

  components: { SelectionSheet },

  props: {
    value: {
      default: undefined,
    },

    items: {
      type: Array,
      default: () => [],
      required: true,
    },

    itemKey: {
      default: "key",
      required: true,
    },

    itemLabel: {
      default: "value",
      required: true,
    },

    pageSize: {
      default: 12,
    },

    title: {
      default: "",
    },
  },

  data() {
    return { localItems: [], search: "", page: 0 };
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },

    paged() {
      return this.filtered.slice(
        this.page * this.pageSize,
        (this.page + 1) * this.pageSize
      );
    },

    filtered() {
      if (this.search == "" || !this.search) return this.localItems;

      let lSearch = this.search.toLocaleLowerCase();
      return this.localItems.filter((e) => {
        if (typeof e !== "object") {
          console.error("GenericSelector the items are not objects got: ", e);
          return true;
        }

        let values = Object.values(e)
          .filter((e) => typeof e === "string" && e !== "")
          .map((e) => e.toLocaleLowerCase());
        if (values.filter((e) => e.includes(lSearch)).length > 0) return true;
        return false;
      });
    },
  },

  watch: {
    items(val) {
      this.localItems = val;
    },
  },

  created() {
    this.localItems = this.items;
  },
};
</script>