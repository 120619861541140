<template>
  <div class="message" color="warning">
    <span>{{ message }}</span>
  </div>
</template>

<script>
export default {
  name: "SOMessage",
  props: ["message"],
};
</script>

<style scoped>
.message {
  width: 100%;
  height: 35px;
  font-size: 1.2rem;
  position: fixed;
  z-index: 9;
  bottom: 0;
  background-color: #6a995e;
  display: flex;
  justify-content: space-evenly;
}

.message > span {
  vertical-align: center;
  text-align: center;
}
</style>