<template>
  <v-card
    no-gutter
    :style="
      'width: 100% !important; box-sizing: border-box; display: flex; flex-flow: column; overflow: hidden; max-height: 100% !important; overflow-y: scroll; overflow: hidden;' +
      (fakeData ? 'height: 500px;' : 'height: 100% !important;') +
      (soMetaBackgroundColorCard
        ? 'background-color: ' + soMetaBackgroundColorCard + ';'
        : '')
    "
    class="justify-center"
  >
    <v-toolbar dense flat style="background: transparent">
      <v-toolbar-title
        :style="soMetaTextColorTitle ? 'color: ' + soMetaTextColorTitle : ''"
        >{{ module.title }}</v-toolbar-title
      >
    </v-toolbar>
    <v-dialog v-model="addDialog" max-width="500px">
      <v-card>
        <v-card-title>
          {{ $t("imageMap.chooseTag") }}
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            autocomplete="off"
            :loading="status.loading"
            :label="$t('module.fields.types')"
            v-model="type"
            :items="[
              { name: $t('module.mapMarkers.node'), value: 0 },
              { name: $t('module.mapMarkers.sensor'), value: 1 },
            ]"
            item-text="name"
            item-value="value"
            prepend-icon="mdi-map-marker"
          ></v-autocomplete>
          <v-divider />
          <v-autocomplete
            autocomplete="off"
            :loading="status.loading"
            :label="$t('module.meta.keys.deveui')"
            v-model="deveui"
            :items="tags"
            item-text="name"
            item-value="deveui"
            prepend-icon="mdi-cellphone-link"
            clearable
          ></v-autocomplete>
          <div v-if="type == 1">
            <v-autocomplete
              autocomplete="off"
              :loading="status.loading"
              :label="$t('module.meta.keys.tagkey')"
              v-model="tagKey"
              :items="keys"
              item-text="label"
              item-value="key"
              prepend-icon="mdi-key"
              clearable
              v-if="type == 1"
            ></v-autocomplete>
            <v-text-field
              :label="$t('module.meta.keys.sufix')"
              v-model="valueSuffix"
              prepend-icon="mdi-temperature-celsius"
              clearable
              v-if="type == 1"
            ></v-text-field>

            <v-text-field
              :label="$t('module.meta.keys.font_size')"
              v-model="fontSize"
              prepend-icon="mdi-format-size"
              v-if="type == 1"
            ></v-text-field>

            <v-card-text align="center" class="subtitle-1 mt-0 pt-0">{{
              $t("module.meta.styles.text.textcolor")
            }}</v-card-text>
            <v-color-picker
              v-if="type == 1"
              v-model="textColor"
              style="margin: 0 auto"
            />
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" text @click="submitDialog">
            {{ $t("common.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container
      align="center"
      class="pt-0"
      style="
        height: 100%;
        max-height: 100% !important;
        max-width: 100% !important;
        box-sizing: border-box;
        flex-grow: 1;
        overflow: hidden;
      "
      justify="center"
    >
      <l-map
        ref="map"
        :min-zoom="minZoom"
        :max-zoom="maxZoom"
        :zoom="curZoom"
        :crs="crs"
        style="height: 100%; width: 100%; max-height: 100%; z-index: 4"
        @click="handleClick"
        @update:zoom="zoomUpdated"
        @ready="updateLayout"
      >
        <l-image-overlay
          v-if="imageUrl"
          style="z-index: 3"
          ref="iOverlay"
          :url="imageUrl"
          :bounds="bounds"
        />
        <l-marker
          v-for="(item, i) in nodeItems"
          :key="item.tag.deveui + i"
          :lat-lng="item.latlng"
          :icon="icon"
        >
          <l-popup>
            <SoContent
              v-on:remove-marker="removeNodeMarker"
              :mode="mode"
              :data="item"
            />
          </l-popup>
          <l-tooltip :options="{ permanent: true, reactive: true }">
            {{ item.tag.name }}
          </l-tooltip>
        </l-marker>

        <l-marker
          v-for="(item, i) in keyItems"
          :key="i"
          :lat-lng="item.latlng"
          :icon="emptyIcon"
        >
          <l-icon iconUrl="" shadowUrl="">
            <v-card-text
              class="text-no-wrap align-center justify-center text-center ma-0 pa-0"
              :style="item.style"
              >{{ item.data }}</v-card-text
            >
          </l-icon>
          <l-popup>
            {{ item.deveui + " - " + item.key }}
            <v-btn
              v-if="mode == 1"
              color="error"
              text
              @click="removeKeyMarker(item)"
            >
              {{ $t("common.delete") }}
            </v-btn>
          </l-popup>
        </l-marker>
      </l-map>
    </v-container>
  </v-card>
</template>

<script>
import "leaflet/dist/leaflet.css";
import Enum from "@/_helpers/Enum";
import { mapState, mapActions } from "vuex";
import { CRS, icon } from "leaflet";
import {
  LMap,
  LImageOverlay,
  LMarker,
  LPopup,
  LTooltip,
  LIcon,
} from "vue2-leaflet";
import SoContent from "@/components/leaflet/SoContent";
import Meta from "@/_helpers/ModuleMeta";

export default {
  name: "ModImageMap",

  mixins: [Meta],

  components: {
    LMap,
    LImageOverlay,
    LMarker,
    LPopup,
    LTooltip,
    LIcon,
    SoContent,
  },

  props: {
    module: {
      default: {},
    },
    mode: {
      default: null,
    },
    timerTick: {
      default: null,
    },
    fakeData: {
      default: false,
    },
  },

  data() {
    return {
      addDialog: false,
      type: 1,
      deveui: null,
      tagKey: null,
      textColor: "#ffffff",
      fontSize: 12,
      valueSuffix: "",
      clickedPos: {
        lat: null,
        lng: null,
      },
      loaded: false,
      imgHeight: 0,
      imgWidth: 0,
      imageUrl: null,
      bounds: [
        [0, 0],
        [720, 970],
      ],
      minZoom: -2,
      maxZoom: 3,
      curZoom: 10,
      crs: CRS.Simple,
      nodeItems: [],
      keyItems: [],
      keyTagData: [],
      icon: icon({
        iconUrl:
          "http://icons.iconarchive.com/icons/custom-icon-design/flatastic-6/512/Circle-icon.png",
        iconSize: [15, 15],
        iconAncor: [10, 10],
      }),
      emptyIcon: icon({
        iconUrl: "/icons/emptyIcon.png",
        iconSize: [15, 15],
        iconAncor: [10, 10],
      }),
    };
  },

  computed: {
    ...mapState("configuration", ["SO_API_BASE_URL"]),
    ...mapState("settings", ["imageMapZoom"]),
    ...mapState("tag", { status: "status", tags: "tags" }),
    ...mapState("tagData", ["keys", "currentTagData"]),
  },

  methods: {
    ...mapActions("tag", ["getTags", "getTagAsync", "getKeysForTag"]),
    ...mapActions("modules", ["update"]),
    ...mapActions("configuration", ["getBaseUrl"]),
    ...mapActions("settings", ["setImageMapZoom"]),
    ...mapActions("tagData", [
      "getCurrentTagdata",
      "getLatestTagDataForModule",
    ]),

    async updateKeyMarkers() {
      this.keyItems = [];
      this.keyTagData = await this.getLatestTagDataForModule({
        moduleId: this.module.dashboardModuleId,
      });

      for (let i = 0; i < this.module.meta.length; i++) {
        let v = this.module.meta[i];
        if (v.key == "so_imagemap_key") {
          this.addKeyMarker(v);
        }
      }
    },

    zoomUpdated(newZoom) {
      if (newZoom != this.curZoom && newZoom != -1)
        this.setImageMapZoom(newZoom);
    },

    getMeta(url) {
      if (url == undefined || url == "") return;
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = url;
      });
    },

    async removeNodeMarker(item) {
      for (let i = 0; i < this.nodeItems.length; i++) {
        if (
          this.nodeItems[i].latlng.lat == item.latlng.lat &&
          this.nodeItems[i].latlng.lng == item.latlng.lng
        ) {
          this.nodeItems.splice(i, 1);
          for (let j = 0; j < this.module.meta.length; j++) {
            if (
              this.module.meta[j].value == item.latlng.lat &&
              this.module.meta[j].valueMatch == item.latlng.lng
            ) {
              // eslint-disable-next-line vue/no-mutating-props
              this.module.meta.splice(j, 1);
              await this.update({
                moduleId: this.module.dashboardModuleId,
                payload: this.module,
              });
            }
          }
          break;
        }
      }
    },
    async removeKeyMarker(item) {
      for (let i = 0; i < this.keyItems.length; i++) {
        if (
          this.keyItems[i].latlng.lat == item.latlng.lat &&
          this.keyItems[i].latlng.lng == item.latlng.lng
        ) {
          this.keyItems.splice(i, 1);
          for (let j = 0; j < this.module.meta.length; j++) {
            if (
              this.module.meta[j].value ==
              item.latlng.lat + "," + item.latlng.lng
            ) {
              // eslint-disable-next-line vue/no-mutating-props
              this.module.meta.splice(j, 1);
              await this.update({
                moduleId: this.module.dashboardModuleId,
                payload: this.module,
              });
            }
          }
          break;
        }
      }
    },

    addKeyMarker(meta) {
      let latlng = meta.value.split(",");
      let tagIdKey = meta.valueMatch.split(",");
      let content = meta.content.split(",");
      let tagData = this.keyTagData.find(
        (td) => td.tagDeveui == tagIdKey[0] && td.key == tagIdKey[1]
      );

      this.keyItems.push({
        id: meta.moduleMetaId,
        deveui: tagIdKey[0],
        key: tagIdKey[1],
        data: tagData ? tagData.value + " " + content[1] : "N/A",
        style: "color: " + content[0] + "; font-size: " + content[2] + "px",
        latlng: { lat: latlng[0], lng: latlng[1] },
      });
    },

    getDefault(item) {
      var val = null;
      var key = null;
      if (item.tag.defaultKey !== undefined) {
        for (let i = 0; i < item.data.length; i++) {
          if (item.data[i].key == item.tag.defaultKey) {
            key = item.data[i].key;
            val = item.data[i].value;
          }
        }
      } else if (item.data[0]) {
        key = item.data[0].key;
        val = item.data[0].value;
      }

      return key + " - " + val;
    },

    async submitDialog() {
      let payload = {};
      if (this.type == 0) {
        payload.key = "so_imagemap";
        payload.content = this.deveui;
        payload.value = this.clickedPos.lat;
        payload.valueMatch = this.clickedPos.lng;

        let tag = await this.getTagAsync(this.deveui);

        this.nodeItems.push({
          tag: tag.tag,
          data: tag.tagData,
          latlng: this.clickedPos,
        });
      } else if (this.type == 1) {
        payload.key = "so_imagemap_key";
        payload.value = this.clickedPos.lat + "," + this.clickedPos.lng;
        payload.valueMatch = this.deveui + "," + this.tagKey;
        payload.content =
          this.textColor + "," + this.valueSuffix + "," + this.fontSize;
      } else return;

      // eslint-disable-next-line vue/no-mutating-props
      this.module.meta.push(payload);

      // Update the module
      await this.update({
        moduleId: this.module.dashboardModuleId,
        payload: this.module,
      });

      // Get fresh tag data and add the new marker to the map
      if (this.type == 1) {
        this.keyTagData = await this.getLatestTagDataForModule({
          moduleId: this.module.dashboardModuleId,
        });

        this.addKeyMarker(payload);
      }

      this.addDialog = false;
    },

    handleClick(event) {
      // Handle the click
      if (this.mode === Enum.DashboardMode.EDIT) {
        this.clickedPos = event.latlng;
        this.addDialog = true;
      }
    },

    async updateLayout() {
      setTimeout(() => {
        this.$refs.map.mapObject.invalidateSize();
        this.$refs.map.fitBounds(this.bounds);
      }, 500);
    },
  },

  async created() {
    await this.getTags();

    this.curZoom = this.imageMapZoom;

    // Loop through attributes and create the object to display tags
    if (this.module.meta == undefined) return;

    this.keyTagData = await this.getLatestTagDataForModule({
      moduleId: this.module.dashboardModuleId,
    });

    for (let i = 0; i < this.module.meta.length; i++) {
      let v = this.module.meta[i];
      if (v.key == "so_imagemap") {
        let tag = await this.getTagAsync(v.content);
        let latlng = { lat: v.value, lng: v.valueMatch };

        if (Object.keys(tag).length > 0) {
          this.nodeItems.push({
            id: v.moduleMetaId,
            tag: tag.tag,
            data: tag.tagData,
            latlng: latlng,
          });
        }
      } else if (v.key == "so_imagemap_key") {
        this.addKeyMarker(v);
      }
    }
  },

  async mounted() {
    if (this.module.image || this.module.imagePath) {
      await this.getBaseUrl();
      let path = this.module.image?.path ?? this.module.imagePath;
      if (path === undefined) {
        console.error("Could not find image requested");
        return;
      }

      this.imageUrl = `${this.SO_API_BASE_URL}/api/image/${path}`;

      let ret = await this.getMeta(this.imageUrl);
      this.bounds[1][0] = ret.height;
      this.bounds[1][1] = ret.width;
      // Set zoom parameters
      this.$refs.map.mapObject.options.zoomSnap = 0.05;
      this.$refs.map.mapObject.options.wheelPxPerZoomLevel = 100;
      this.$refs.map.mapObject.options.wheelDebounceTime = 5;

      this.$refs.iOverlay.setBounds(this.bounds);
    } else {
      this.imageUrl = "missing-img.png";
      this.bounds[1][0] = 480;
      this.bounds[1][1] = 750;
    }

    await this.updateLayout();
  },

  watch: {
    imgHeight(val) {
      this.bounds[1][0] = val;
    },

    imgWidth(val) {
      this.bounds[1][1] = val;
    },

    async deveui(val, oldVal) {
      if (val != oldVal) {
        await this.getKeysForTag({ tagId: val });
      }
    },

    timerTick(v) {
      if (v % 6 == 0) {
        this.updateKeyMarkers();
      }
    },
  },
};
</script>
