<template>
  <v-container pa-0 ma-0 fluid>
    <v-col cols="12" sm="12" md="6" class="text-center pt-0">
      <v-btn
        class="mx-2"
        style="margin: 0 auto"
        color="accent"
        @click="addSlider"
        >{{ $t("module.meta.addSlider") }}</v-btn
      >
    </v-col>
    <v-col class="pa-0 d-flex flex-wrap">
      <v-card
        shaped
        class="ma-3 pb-0"
        v-for="(slider, i) in sliders"
        :key="i"
        style="max-width: 450px"
      >
        <v-card-title>{{ $t("module.fields.title") }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-container>
                <div class="palette-chip">
                  <v-row class="pa-0">
                    <v-col class="py-0">
                      <v-autocomplete
                        autocomplete="off"
                        :label="$t('alarm.fields.tag')"
                        v-model="slider.tag"
                        :items="tags"
                        required
                        item-text="name"
                        item-value="deveui"
                        prepend-icon="mdi-cellphone-link"
                        clearable
                        @change="deveuiChanged(slider)"
                      ></v-autocomplete>

                      <v-autocomplete
                        autocomplete="off"
                        :label="$t('alarm.fields.tagDataKey')"
                        v-model="slider.tagKey"
                        :items="slider.keys"
                        required
                        item-text="label"
                        item-value="key"
                        prepend-icon="mdi-key"
                        clearable
                        @change="updateMeta(slider)"
                      ></v-autocomplete>

                      <v-text-field
                        type="text"
                        :label="$t('common.explanation')"
                        v-model="slider.hint"
                        prepend-icon="mdi-label-outline"
                        @change="updateMeta(slider)"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      xxs="2"
                      xs="2"
                      sm="2"
                      md="2"
                      style="margin: 0; padding: 0"
                    >
                      <v-text-field
                        type="number"
                        label="Min"
                        v-model="slider.min"
                        @change="updateMeta(slider)"
                      ></v-text-field>

                      <v-text-field
                        type="number"
                        label="Max"
                        v-model="slider.max"
                        @change="updateMeta(slider)"
                      ></v-text-field>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            fab
                            small
                            color="error"
                            @click="removeSlider(slider)"
                          >
                            <v-icon style="margin: 1rem auto" v-on="on"
                              >mdi-delete</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>
                          {{ $t("common.delete") }}
                        </span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </div>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "ControlSliderPicker",

  props: {
    metas: {
      default: () => [],
    },
  },

  data() {
    return {
      sliders: [],
    };
  },

  computed: {
    ...mapState("tag", ["tags"]),
    ...mapState("tagData", ["keys"]),
  },

  methods: {
    ...mapActions("tag", ["getTags", "getKeysForTag"]),

    addSlider() {
      this.sliders.push({
        tag: "",
        tagKey: "",
        hint: "",
        min: 0,
        max: 30,
        keys: [],
      });
    },

    verifySlider(slider) {
      return Boolean(
        slider.tag &&
          slider.tagKey &&
          slider.min !== "" &&
          slider.max !== "" &&
          slider.min < slider.max
      );
    },

    updateMeta(slider) {
      // Check if worth updating
      if (!this.verifySlider(slider)) return;

      var meta = [];
      for (var s of this.sliders) {
        if (!this.verifySlider(s)) continue;
        meta.push({
          key: "so_value_slider",
          valueMatch: s.tagKey,
          value: s.tag,
          content: `${s.hint};${s.min};${s.max}`,
        });
      }
      this.$emit("update", meta);
    },

    removeSlider(slider) {
      this.sliders = this.sliders.filter((s) => s !== slider);

      var meta = [];
      for (var s of this.sliders) {
        if (!this.verifySlider(s)) continue;
        meta.push({
          key: "so_value_slider",
          valueMatch: s.tagKey,
          value: s.tag,
          content: `${s.hint};${s.min};${s.max}`,
        });
      }

      this.$emit("update", meta);
    },

    async deveuiChanged(slider) {
      await this.getKeysForTag({ tagId: slider.tag });

      slider.keys = this.keys;
    },
  },

  async mounted() {
    await this.getTags();

    for (let meta of this.metas) {
      // deconstruct content
      let content = meta.content.split(";");

      await this.getKeysForTag({ tagId: meta.value });

      this.sliders.push({
        tag: meta.value,
        tagKey: meta.valueMatch,
        hint: content[0],
        min: content[1],
        max: content[2],
        keys: this.keys,
      });
    }
  },

  components: {},
};
</script>

<style></style>
