<template>
  <v-container>
    <v-row style="overflow-y: scroll">
      <v-col cols="12" sm="3" md="3" lg="3" xs="12">
        <gmap-map
          ref="preview-gmap-ref"
          :zoom="10"
          :center="previewPosition"
          style="height: 100%; min-height: 150px; width: 100%"
        >
          <db-map-marker ref="previewMarker" v-model="localValue" preview />
        </gmap-map>
      </v-col>

      <v-col>
        <color-picker
          v-model="backgroundColor"
          :label="$t('colorPicker.backgroundColor')"
          @change="updateIconStyle"
        />
        <color-picker
          v-model="iconColor"
          :label="$t('colorPicker.iconColor')"
          @change="updateIconStyle"
        />

        <db-map-icon-selector v-model="iconKey" @change="updateIconStyle" />

        <v-slider
          v-model="size"
          min="10"
          max="100"
          :label="$t('common.size')"
          persistent-hint
          thumb-label
          thumb-size="sm"
          @change="updateIconStyle"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import iconMixin from "../../../_helpers/iconMixin";
import ColorPicker from "../customForm/ColorPicker.vue";
import DbMapIconSelector from "./DbMapIconSelector.vue";
import DbMapMarker from "./DbMapMarker.vue";

export default {
  name: "DbMapIconForm",

  components: { DbMapIconSelector, ColorPicker, DbMapMarker },

  mixins: [iconMixin],

  props: {
    value: {
      default: "{}",
    },
  },

  model: { prop: "value" },

  data() {
    return {
      size: 30,
      iconColor: undefined,
      backgroundColor: undefined,
      iconKey: this.defaultMdiIconKey,
    };
  },

  computed: {
    api: gmapApi,

    previewPosition() {
      return {
        lat: 58.39118,
        lng: 13.84506,
      };
    },

    localValue: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },

    icFromMarker() {
      if (!this.value) return { size: 30, iconUrl: undefined };

      try {
        // Try to json parse the value
        let parsed = JSON.parse(this.value);
        return {
          size: parsed?.size ?? 30,
          iconUrl: parsed?.iconUrl ?? undefined,
        };
      } catch {
        console.error("Could not parse json");
        return { size: 30, iconUrl: undefined };
      }
    },
  },

  methods: {
    updateIconStyle() {
      // Construct the complete IconStyling and store to localValue
      this.constructValue(true);
      this.$refs.previewMarker.redraw();
    },

    constructValue(forceUpdateIcon = false) {
      let payload = undefined;
      if (!forceUpdateIcon)
        payload = {
          size: this.icFromMarker.size,
          iconUrl:
            this.icFromMarker.iconUrl ??
            this.cf_iconUrl(
              this.iconKey ?? this.defaultMdiIconKey,
              this.backgroundColor,
              this.iconColor
            ),
        };
      else
        payload = {
          size: this.size ?? 30,
          iconUrl: this.cf_iconUrl(
            this.iconKey ?? this.defaultMdiIconKey,
            this.backgroundColor,
            this.iconColor
          ),
        };

      this.localValue = JSON.stringify(payload);
    },
  },

  watch: {
    value() {
      this.constructValue();
      this.$refs.previewMarker.redraw();
    },
  },
};
</script>