<template>
  <v-dialog v-model="localValue" max-width="900" @click:outside="abort()">
    <v-card>
      <v-card-title>{{ $t("map.create") }}</v-card-title>

      <v-stepper alt-labels v-model="e1" flat>
        <v-stepper-header>
          <v-stepper-step step="1">{{ $t("map.select.node") }}</v-stepper-step>
          <v-divider />
          <v-stepper-step step="2">{{
            $t("map.select.dashboard")
          }}</v-stepper-step>
          <v-divider />
          <v-stepper-step step="3"> {{ $t("map.iconStyling") }}</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-switch
              class="ml-3"
              v-model="payload.useTagPosition"
              :label="$t('map.useNodePosition')"
            />
            <node-selector v-model="payload.tagId" />
          </v-stepper-content>
          <v-stepper-content step="2">
            <generic-selector
              v-model="payload.templateDashboardId"
              item-key="templateDashboardId"
              item-label="name"
              :items="dashboards"
              :title="$t('map.select.dashboard')"
            />
          </v-stepper-content>
          <v-stepper-content step="3">
            <db-map-icon-form v-model="payload.iconStyle" />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="goBack" color="grey" text>{{
          e1 === 1 ? $t("common.cancel") : $t("common.back")
        }}</v-btn>
        <v-btn @click="advance" color="primary">{{
          e1 !== 3 ? $t("common.next") : $t("common.save")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";

import NodeSelector from "../../common/NodeSelector.vue";
import GenericSelector from "../../common/GenericSelector.vue";
import DbMapIconForm from "./DbMapIconForm.vue";

export default {
  name: "DbMapForm",

  components: { NodeSelector, GenericSelector, DbMapIconForm },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    position: {
      type: Object,
      default: () => {},
    },

    marker: {
      type: Object,
      default: () => {},
    },
  },

  model: {
    prop: "value",
  },

  data() {
    return {
      e1: 1,
      payload: {
        tagId: "",
        templateDashboardId: "",
        iconStyle: "",
      },
    };
  },

  computed: {
    ...mapState("templateDashboard", ["dashboards"]),

    localValue: {
      get() {
        return this.value;
      },

      set(v) {
        this.$emit("input", v);
      },
    },
  },

  methods: {
    ...mapActions("templateDashboard", ["getTemplateDashboards"]),

    closeDialog() {
      this.localValue = false;
    },

    submit() {
      this.localValue = false;
      this.$emit("submit", this.payload);
    },

    advance() {
      if (this.e1 === 3) {
        this.submit();
        return;
      }

      this.e1++;
    },

    goBack() {
      if (this.e1 === 1) {
        this.abort();
        this.localValue = false;
        return;
      }

      this.e1--;
    },

    abort() {
      this.e1 = 1;
      this.$emit("abort");
    },

    setValuesFromMarker() {
      if (!this.marker) return;
      var keys = Object.keys(this.marker);
      this.payload = {
        ...this.marker,
      };

      if (!keys.includes("templateDashboardId"))
        this.payload.templateDashboardId = "";
      if (!keys.includes("tagId")) this.payload.tagId = "";
      if (!keys.includes("iconStyle")) this.payload.iconStyle = "";
    },
  },

  async created() {
    await this.getTemplateDashboards();

    this.setValuesFromMarker();
  },

  watch: {
    marker(value) {
      if (value === undefined) return;

      this.setValuesFromMarker();
    },
  },
};
</script>