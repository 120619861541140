var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{style:('width: 100% !important; height: 100% !important; overflow: hidden' +
    _vm.backgroundColor)},[(!_vm.multiSensor)?_c('v-row',{style:(`${
      !_vm.horizontalTitle ? 'display: grid; align-items: start;' : ''
    } height: 100%; margin: 0; `)},[_c('v-col',{staticClass:"d-flex justify-content-left",style:(`${
        _vm.horizontalTitle
          ? 'height: 100%; align-items: center;'
          : 'align-content: top; align-items: top;'
      } ${_vm.textColor} ${_vm.titleBackground};`)},[_c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('span',{class:_vm.fontStyle.title.weight + ' text-left',style:(`width: 100%; font-size: ${_vm.fontStyle.title.size}rem`)},[_vm._v(" "+_vm._s(_vm.module.name === null ? "" : _vm.module.title)+" ")])]),_c('v-row',{attrs:{"no-gutters":""}},[_c('span',{class:_vm.fontStyle.title.weight + ' text-left',style:(`width: 100%; font-size: ${
              _vm.fontStyle.title.size * 0.2 + 0.6
            }rem`),attrs:{"v-if":!_vm.multiSensor}},[_vm._v(_vm._s(_vm.singleSeries[0] ? _vm.humanDate(_vm.singleSeries[0].createdAt) : "N/A"))])])],1)]),_c('v-col',{staticClass:"d-flex align-center justify-center flex-wrap",style:(`
          padding: 0;
          ${_vm.horizontalTitle ? 'height: 100%;' : ''}`),attrs:{"cols":_vm.horizontalTitle ? 6 : 12}},[_c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('span',{class:`text-center
             ${_vm.fontStyle.value.weight}`,style:('width: 100%;' +
              _vm.textColor +
              ';font-size: ' +
              _vm.fontStyle.value.size +
              'rem')},[_vm._v(" "+_vm._s(_vm.singleSeries[0] ? _vm.getValue( _vm.singleSeries[0].tagDeveui, _vm.singleSeries[0].key, _vm.singleSeries[0].value ) : "N/A")+" "),(_vm.metaHasKey(_vm.module.meta, _vm.MetaEnum.ICON))?_c('v-icon',{style:(_vm.textColor),attrs:{"medium":_vm.horizontalTitle,"large":!_vm.horizontalTitle}},[_vm._v(_vm._s(_vm.getMeta(_vm.module.meta, _vm.MetaEnum.ICON).value))]):_vm._e(),(!_vm.metaHasKey(_vm.module.meta, _vm.MetaEnum.HIDETRENDICON))?_c('v-icon',{attrs:{"medium":_vm.horizontalTitle,"large":!_vm.horizontalTitle && _vm.fontStyle.value.size < 2.8,"x-large":!_vm.horizontalTitle && _vm.fontStyle.value.size > 2.8,"size":"","color":_vm.singleSeries[0] && _vm.singleSeries[1]
                  ? _vm.getTrendColor(
                      _vm.singleSeries[0].value,
                      _vm.singleSeries[1].value
                    )
                  : _vm.getTrendColor(null, null)}},[_vm._v(_vm._s(_vm.singleSeries[0] && _vm.singleSeries[1] ? _vm.getTrendIcon(_vm.singleSeries[0].value, _vm.singleSeries[1].value) : _vm.getTrendIcon(null, null)))]):_vm._e()],1)]),_c('v-row',{attrs:{"no-gutters":"","align":"start"}},[(!_vm.metaHasKey(_vm.module.meta, _vm.MetaEnum.SINGLEHIDENODEINFO))?_c('span',{staticClass:"text-center",style:('width: 100%;' +
              _vm.textColor +
              ';font-size: ' +
              (_vm.fontStyle.value.size * 0.2 + 0.5) +
              'rem')},[_vm._v(" "+_vm._s(`${_vm.singleSeries[0] ? _vm.singleSeries[0].tag.name : "N/A"} - ${ _vm.singleSeries[0] ? _vm.singleSeries[0].label ? _vm.singleSeries[0].label : "N/A" : "N/A" }`)+" ")]):_vm._e()])],1),(_vm.hasSparkline)?_c('div',{staticClass:"d-block mt-2",staticStyle:{"width":"100%"}},[_c('sparkline',{attrs:{"sparkline-data":_vm.singleSeries.map((it) => ({
              value: it.value,
            })),"color":_vm.sparkLineColor,"label-color":_vm.sparkLineLabelColor}})],1):_vm._e()])],1):_c('div',{staticClass:"py-0 d-flex flex-column",staticStyle:{"height":"100%"}},[_c('div',[_c('v-col',{style:(`height: 48px; ${_vm.textColor} ${_vm.titleBackground}`),attrs:{"no-gutters":"","align":"start"}},[_c('span',{class:_vm.fontStyle.title.weight + ' text-left',style:(`width: 100%; font-size: ${_vm.fontStyle.title.size}rem`)},[_vm._v(" "+_vm._s(_vm.module.name === null ? "" : _vm.module.title)+" ")])])],1),(_vm.data.length > 0)?_c('div',_vm._l((_vm.data),function(item,index){return _c('div',{key:index,staticStyle:{"height":"100%","max-width":"100%","padding":"0 12px"}},[_c('v-row',{staticStyle:{"height":"100%"}},[_c('v-col',{staticClass:"pa-1 d-flex justify-center flex-column",attrs:{"no-gutters":""}},[_c('span',{staticClass:"text-left pl-2",style:('width: 100%;' +
                _vm.textColor +
                ';font-size: ' +
                (_vm.fontStyle.value.size * 0.2 + 0.7) +
                'rem')},[_vm._v(" "+_vm._s(!_vm.metaHasKey(_vm.module.meta, _vm.MetaEnum.SINGLEHIDENODEINFO) ? `${item[0].tag.name} - ${item[0].label}` : `${item[0].label}`)+" ")]),_c('span',{staticClass:"text-left pl-2",class:_vm.fontStyle.title.weight + ' text-left',style:(`width: 100%; font-size: ${
                _vm.fontStyle.title.size * 0.2 + 0.6
              }rem`)},[_vm._v(_vm._s(_vm.humanDate(item[0].createdAt)))])]),_c('v-col',{staticClass:"pa-1 d-flex justify-center flex-column",attrs:{"no-gutters":""}},[_c('span',{class:`text-center
             ${_vm.fontStyle.value.weight}`,style:('width: 100%;' +
                _vm.textColor +
                ';font-size: ' +
                _vm.fontStyle.value.size +
                'rem')},[_vm._v(" "+_vm._s(_vm.getValue(item[0].tagDeveui, item[0].key, item[0].value))+" "),(_vm.metaHasKey(_vm.module.meta, _vm.MetaEnum.ICON))?_c('v-icon',{style:(_vm.textColor),attrs:{"medium":_vm.horizontalTitle}},[_vm._v(_vm._s(_vm.getMeta(_vm.module.meta, _vm.MetaEnum.ICON).value))]):_vm._e(),(!_vm.metaHasKey(_vm.module.meta, _vm.MetaEnum.HIDETRENDICON))?_c('v-icon',{attrs:{"large":_vm.fontStyle.value.size < 2.8,"x-large":_vm.fontStyle.value.size > 2.8,"size":"","color":_vm.getTrendColor(
                    item[0].value,
                    item[1] ? item[1].value : item[0].value
                  )}},[_vm._v(_vm._s(_vm.getTrendIcon( item[0].value, item[1] ? item[1].value : item[0].value )))]):_vm._e()],1)]),(_vm.hasSparkline)?_c('div',{staticClass:"d-block mt-2",staticStyle:{"width":"100%"}},[_c('sparkline',{attrs:{"sparkline-data":item.map((it) => ({
                  value: it.value,
                })),"color":_vm.sparkLineColor,"label-color":_vm.sparkLineLabelColor}})],1):_vm._e()],1),(index < _vm.data.length - 1)?_c('v-divider'):_vm._e()],1)}),0):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }