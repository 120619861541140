<template>
  <div style="width: 100%; height: 100%">
    <div class="d-flex flex-column justify-start align-start pa-2 fill-height">
      <div class="pa-2" style="width: 100%" v-if="isForm">
        <v-form
          ref="form"
          class="d-flex justify-space-between align-center"
          :lazy-validation="true"
          @submit.prevent="saveDashboard"
          v-model="valid"
        >
          <v-text-field
            prepend-inner-icon="mdi-pen"
            :label="$t('dashboard.fields.name')"
            name="dashboardName"
            v-model="name"
            flat
            dense
            :rules="[
              (v) => !!v || this.$t('alarm.validation.nameRequired'),
              (v) => (v && v.length <= 255) || this.$t('alarm.validation.max'),
            ]"
          />

          <v-btn type="submit" class="ml-2" color="primary">
            {{ $t("common.save") }}
          </v-btn>
        </v-form>
      </div>

      <v-slider
        style="width: 100%"
        v-if="positions.length > 0"
        v-model="positionSliceIndex"
        :min="1"
        :max="positions.length"
        :label="$t('map.stepPositions')"
      />
      <gmap-map
        ref="gmapRef"
        :zoom="7"
        :center="center"
        style="height: 100%; width: 100%; min-height: 100%"
        @click="mapClicked"
        @rightclick="mapRightClicked"
      >
        <gmap-polyline
          v-if="positions.length > 0"
          :path="positions.slice(0, positionSliceIndex)"
          :editable="false"
          :options="{
            strokeColor: '#2196F3',
            strokeWeight: '3',
            zIndex: 1,
          }"
        />
        <gmap-polyline
          v-if="positions.length > 0"
          :path="positions.slice(0, positionSliceIndex)"
          :editable="false"
          :options="{
            strokeColor: 'black',
            strokeWeight: '4',
            zIndex: 0,
          }"
        />

        <db-map-marker
          v-for="(position, i) in positionMarkers"
          :key="'pos-' + i"
          :position="position"
          :label="humanDate(position.createdAt)"
          :latitude="position.lat"
          :longitude="position.lng"
          :tag-id="position.deveui"
          position-marker
          :z-index="2"
        ></db-map-marker>

        <db-map-marker
          v-for="(v, i) in markers"
          @click="(e) => markerClicked(e, v)"
          @contextmenu="(e) => contextMenuMarkerClicked(e, v)"
          :value="v.iconStyle"
          :key="'marker-' + k + '-' + i"
          :preview="isForm"
          :latitude="v.latitude"
          :longitude="v.longitude"
        />
      </gmap-map>

      <db-map-form
        v-if="selectedPos != undefined"
        v-model="formDialog"
        :position="selectedPos"
        :marker="contextMenu?.marker"
        @submit="dbMapFormSubmit"
        @abort="abortForm"
      />
    </div>

    <div
      v-if="contextMenu !== undefined && permitted('Dashboard.Update')"
      :style="`top: ${contextMenu.position.y}px; left: ${contextMenu.position.x}px`"
      class="context-menu"
    >
      <div v-if="contextMenu.marker != undefined">
        <div
          class="context-menu-item"
          @click="openEditMarker(contextMenu.marker)"
          v-if="permitted('Dashboard.Update')"
        >
          <v-icon small color="primary">mdi-pen</v-icon>
          {{ $t("common.edit") }}
        </div>

        <div
          class="context-menu-item"
          @click="
            () => {
              this.positionFormDialog = true;
            }
          "
          v-if="
            permitted('Dashboard.View') && contextMenu.marker.useTagPosition
          "
        >
          <v-icon small color="success">mdi-map</v-icon>
          {{ $t("map.displayHistory") }}
        </div>

        <div
          class="context-menu-item"
          @click="clearPositions"
          v-if="permitted('Dashboard.View') && positions.length > 0"
        >
          <v-icon small color="primary">mdi-close</v-icon>
          {{ $t("map.clearPositions") }}
        </div>

        <div
          class="context-menu-item"
          @click="confirmDelete = true"
          v-if="permitted('Dashboard.Delete')"
        >
          <v-icon small color="error">mdi-delete</v-icon>
          {{ $t("common.delete") }}
        </div>
      </div>
      <div v-else>
        <div
          class="context-menu-item"
          @click="formOpen(contextMenu.event)"
          v-if="permitted('Dashboard.Create')"
        >
          <v-icon small color="error">mdi-plus</v-icon>
          {{ $t("common.create") }}
        </div>

        <div
          class="context-menu-item"
          @click="clearPositions"
          v-if="permitted('Dashboard.View') && positions.length > 0"
        >
          <v-icon small color="primary">mdi-close</v-icon>
          {{ $t("map.clearPositions") }}
        </div>
      </div>
    </div>

    <delete-dialog
      :dialog="confirmDelete"
      v-on:confirm-delete="removeMarker(contextMenu.marker)"
      v-on:cancel-dialog="confirmDelete = false"
    />

    <div
      v-if="tag && templateDashboardId"
      class="db-map-side-dashboard"
      :style="dashboardContainerType(1)"
    >
      <template-dashboard-container
        :tags="[tag]"
        :template-dashboard-id="templateDashboardId"
        :dashboard-type="1"
        @close="
          () => {
            tag = undefined;
            templateDashboardId = undefined;
          }
        "
      />
    </div>

    <so-dialog
      v-model="positionFormDialog"
      @accept="fetchAndSetPositions"
      :width="700"
    >
      <v-card-title>{{ $t("map.dateTimeSpanSelection") }}</v-card-title>
      <v-card-text>
        <h3>{{ $t("map.dateFrom") }}</h3>
        <so-date-time-picker v-model="from" />
      </v-card-text>
      <v-card-text>
        <h3>{{ $t("map.dateTo") }}</h3>
        <so-date-time-picker v-model="to" />
      </v-card-text>
    </so-dialog>
  </div>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import iconMixin from "../../../_helpers/iconMixin";
import { templateDashboardMixin } from "../../../_helpers/templateDashboardMixin";
import { mdiThermometerAlert } from "@mdi/js";
import { mapActions, mapState } from "vuex";
import { DashboardTypes } from "../../../_helpers/CsharpEnum";

import DbMapMarker from "./DbMapMarker.vue";
import DbMapForm from "./DbMapForm.vue";
import TemplateDashboardContainer from "../TemplateDashboardContainer.vue";
import DeleteDialog from "../../common/DeleteDialog.vue";
import SoDialog from "../../common/SoDialog.vue";
import dashboardRepository from "../../../api/repositories/dashboardRepository";
import { addHours } from "date-fns";
import tagRepository from "../../../api/repositories/tagRepository";
import SoDateTimePicker from "../../common/SoDateTimePicker.vue";

export default {
  name: "DbMap",

  mixins: [iconMixin, templateDashboardMixin],

  components: {
    DbMapForm,
    DbMapMarker,
    TemplateDashboardContainer,
    DeleteDialog,
    SoDialog,
    SoDateTimePicker,
  },

  props: {
    isForm: {
      type: Boolean,
      default: false,
    },

    dashboardId: {
      default: undefined,
    },
  },

  data() {
    return {
      name: "",
      valid: false,
      loaded: false,
      formDialog: false,
      center: { lat: 58.39118, lng: 13.84506 },
      selectedPos: { lat: 0, lng: 0 },
      markers: [],

      from: addHours(new Date(), -4),
      to: new Date(),
      positions: [],
      positionFormDialog: false,
      positionSliceIndex: 1,

      confirmDelete: false,

      contextMenu: undefined,

      k: 0,

      label: undefined,

      // FIXME: Temporary should be included in the "markers" array
      ic: undefined,
      scale: 2,

      tag: undefined,
      templateDashboardId: undefined,
    };
  },

  computed: {
    api: gmapApi,

    ...mapState("dashboards", ["currentDashboard"]),

    positionMarkers() {
      if (this.positions.length == 0) return [];

      return this.positions.slice(0, this.positionSliceIndex);
    },
  },

  methods: {
    ...mapActions("dashboards", ["create", "getDashboard"]),

    markerClicked(_, marker) {
      this.tag = undefined;
      this.templateDashboardId = undefined;
      if (marker === undefined) return;

      this.$nextTick(() => {
        if (marker.templateDashboardId)
          this.templateDashboardId = marker.templateDashboardId;
        if (marker.tagId) this.tag = marker.tagId;
      });
    },

    async fetchAndSetPositions() {
      this.positions = [];
      if (this.contextMenu?.marker == undefined) {
        console.error("No marker in context menu");
        return;
      }

      this.positions = await tagRepository
        .getPositions(this.contextMenu.marker.tagId, this.from, this.to)
        .then((d) => d.data)
        .catch(() => []);
      this.positionSliceIndex = this.positions.length;
      this.contextMenuBlur();

      this.$refs.gmapRef.$mapPromise.then(() => {
        if (this.positions.length > 0) {
          let bounds = new this.api.maps.LatLngBounds();
          this.positions.forEach((d) => {
            bounds.extend({ lat: d.lat, lng: d.lng });
          });

          this.$refs.gmapRef.$mapObject.fitBounds(bounds);
        }
      });
    },

    clearPositions() {
      this.contextMenuBlur();
      this.positions = [];
      this.positionSliceIndex = 1;
    },

    contextMenuMarkerClicked(e, marker) {
      this.contextMenu = {
        marker,
        event: e,
        position: {
          x: e.domEvent.clientX + 10,
          y: e.domEvent.clientY + 10,
        },
      };
    },

    contextMenuBlur() {
      this.contextMenu = undefined;
      this.selectedPos = undefined;
    },

    openEditMarker(marker) {
      this.selectedPos = {
        lat: marker.latitude,
        lng: marker.longitude,
      };

      if (marker?.latitude && marker?.longitude) this.formDialog = true;
    },

    async removeMarker(marker) {
      this.confirmDelete = false;
      // Call to remove marker from dashboard
      await dashboardRepository.removeDashboardMarker(marker.dashboardMarkerId);

      // Fetch markers for map dashboard
      await this.getDashboard({ dashboardId: this.dashboardId });
      this.markers = [];

      this.$nextTick(
        () => (this.markers = this.currentDashboard?.markers ?? [])
      );

      this.contextMenu = undefined;
    },

    mapRightClicked(e) {
      this.contextMenu = {
        marker: undefined,
        event: e,
        position: {
          x: e.domEvent.clientX + 5,
          y: e.domEvent.clientY + 5,
        },
      };
    },

    mapClicked(e) {
      if (this.tag || this.templateDashboardId) {
        this.tag = undefined;
        this.templateDashboardId = undefined;
      }

      this.contextMenuBlur();

      if (e?.latLng === undefined) return;

      if (this.isForm) return this.formOpen(e);
    },

    formOpen(e) {
      this.selectedPos = {
        lat: e?.latLng?.lat() ?? 0,
        lng: e?.latLng?.lng() ?? 0,
      };

      // TODO: Remove
      let sz = 35;
      let url = this.getIconAsUrl(this.ICONTYPE.DEFAULT, mdiThermometerAlert);
      this.ic = {
        url: url,
        size: new this.api.maps.Size(sz, sz),
        scaledSize: new this.api.maps.Size(sz, sz),
        labelOrigin: new this.api.maps.Point(sz / 2, -30),
      };

      this.formDialog = true;
    },

    abortForm() {
      this.selectedPos = undefined;
      this.contextMenuBlur();
    },

    async dbMapFormSubmit(payload) {
      // If dashboardId is present we should create / update the marker instad of just pushing markers like we do in the form
      if (this.dashboardId != undefined) {
        // Create / update the marker add the id to the payload
        let pl = {
          dashboardId: this.dashboardId,
          dashboardMarkerId:
            this.contextMenu?.marker?.dashboardMarkerId ?? crypto.randomUUID(),
          ...payload,
          latitude: this.contextMenu?.marker?.latitude ?? this.selectedPos.lat,
          longitude:
            this.contextMenu?.marker?.longitdue ?? this.selectedPos.lng,
        };

        await dashboardRepository.createOrUpdateMarker(pl.dashboardId, pl);

        // Fetch the new markers
        await this.getDashboard({ dashboardId: this.dashboardId });
        this.markers = [];
        this.contextMenu = undefined;
        this.selectedPos = undefined;

        this.$nextTick(
          () => (this.markers = this.currentDashboard?.markers ?? [])
        );
        return;
      }

      let pl = {
        latitude: this.selectedPos.lat,
        longitude: this.selectedPos.lng,
        ...payload,
      };

      this.markers.push(pl);
      this.selectedPos = undefined;
    },

    async saveDashboard() {
      if (!this.$refs.form.validate()) {
        this.valid = false;
        return;
      }

      // Construct payload
      let payload = {
        name: this.name,
        categoryId: this.$route?.params?.dashboardCategoryId ?? undefined,
        markers: this.markers,
      };

      var result = await this.create({
        payload: payload,
        dashboardType: DashboardTypes.MAP,
      });
      if (result === true) this.$router.push("/dashboard");
    },

    async updateDashboard() {
      if (this.dashboardId === undefined)
        console.error("Could not update dashboard missing DashboardId");

      if (!this.$refs.form.validate()) {
        this.valid = false;
        return;
      }

      // Construct payload
      let payload = {
        name: this.name,
        categoryId: this.$route?.params?.dashboardCategoryId ?? undefined,
        markers: this.markers,
      };

      await this.update({ dashboardId: this.dashboardId, payload: payload });

      // TODO: Go to the dashboard and update
      this.$router.push("/dashboard");
    },
  },

  created() {},

  mounted() {
    this.$refs.gmapRef.$mapPromise.then(() => {
      // Fit to bounds of tags in here
      this.loaded = true;
      if (this.dashboardId) this.markers = this.currentDashboard?.markers ?? [];

      if (this.markers.length > 0) {
        let bounds = new this.api.maps.LatLngBounds();
        this.markers.forEach((d) => {
          bounds.extend({ lat: d.latitude, lng: d.longitude });
        });

        this.$refs.gmapRef.$mapObject.fitBounds(bounds);
      }
    });
  },

  watch: {
    dashboardId(val) {
      if (this.isForm) return;

      if (val === undefined || val === 0) {
        this.markers = [];
        return;
      }

      // Get current dashboard with markers to set the markers
      this.$nextTick(() => {
        this.markers = this.currentDashboard?.markers ?? [];
      });
    },
  },
};
</script>

<style>
.db-map-side-dashboard {
  position: fixed;
  height: 100vh;
  top: 0px;
  right: 0px;
  z-index: 9;
  overflow-y: scroll;
}

.context-menu {
  z-index: 90;
  position: fixed;
  background-color: white;
  display: block;
  margin: 0;
  list-style-type: none;
  list-style: none;
  border-radius: 3px;
}

.context-menu-item {
  padding: 0.4rem 1rem;
  min-width: 150px;
  display: flex;
  justify-content: flex-start;
  align-content: center;
}

.context-menu-item > i {
  margin-right: 1rem;
}

.context-menu-item:nth-child(odd) {
  background: rgba(99, 99, 99, 0.05);
}

.context-menu-item:hover {
  background: rgba(99, 99, 99, 0.4);
  cursor: pointer;
  color: white;
}
</style>