<template>
  <v-card style="width: 100% !important; height: 100%; overflow: hidden">
    <v-row
      :style="
        'height: 100%; margin: 0px' + (fakeData ? ';min-height: 300px' : '')
      "
    >
      <v-toolbar dense flat class="justify-center">
        <v-toolbar-title>{{ module.title }}</v-toolbar-title>
      </v-toolbar>
      <v-container class="pa-0 ma-0 align-center justify-center fill-height">
        <v-row
          v-for="(slider, i) in sensorSliders"
          :key="i"
          class="pa-0 ma-0 justify-center loopad"
        >
          <v-row class="justify-center" style="width: 100%">
            <v-label>{{
              `${slider.tagName ? slider.tagName : slider.tag} - ${
                slider.tagKey ? slider.tagKey : slider.key
              }`
            }}</v-label>
          </v-row>
          <v-row class="ml-5 mr-2 justify-center" style="width: 100%">
            <v-slider
              v-model="slider.value"
              persistent-hint
              thumb-label
              :hint="slider.hint"
              :min="slider.min"
              :max="slider.max"
            >
              <template v-slot:append>
                <v-text-field
                  v-model="slider.value"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  type="number"
                  style="width: 45px"
                  :min="slider.min"
                  :max="slider.max"
                ></v-text-field> </template
            ></v-slider>
          </v-row>
        </v-row>
        <v-card-actions
          class="mt-0 pt-0 mb-3"
          style="max-width: 100%; width: 100%"
        >
          <v-spacer />
          <v-btn
            class="mb-5"
            style="margin: 0 auto; min-width: 10vh"
            color="warning"
            @click="resetValues()"
          >
            {{ $t("common.reset") }}
          </v-btn>
          <v-spacer />
          <v-btn
            class="mb-5"
            style="margin: 0 auto; min-width: 10vh"
            color="primary"
            @click="dialog = true"
            :disabled="buttonDisabled"
          >
            {{ $t("common.save") }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-container>
    </v-row>
    <v-dialog v-model="dialog" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("common.save") }}</span>
        </v-card-title>
        <v-card-text>
          {{ $t("common.verify") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            {{ $t("common.no") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveValues()">
            {{ $t("common.yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Meta from "@/_helpers/ModuleMeta";

export default {
  name: "ControlSliders",

  mixins: [Meta],

  props: ["module", "fakeData"],

  data() {
    return {
      dialog: false,
      buttonDisabled: false,
      buttonTimeout: null,
      sensorSliders: [],
    };
  },

  methods: {
    ...mapActions("tag", ["setTagData"]),
    ...mapActions("tagData", ["getCurrentTagdata"]),

    resetValues() {
      for (let slider of this.sensorSliders) slider.value = slider.initialValue;
    },

    async saveValues() {
      if (this.fakeData) return;

      for (let slider of this.sensorSliders) {
        if (slider.value == slider.initialValue) continue;

        let payload = { key: slider.tagKey, value: slider.value };
        await this.setTagData({
          tagId: slider.tag,
          payload: payload,
        });
        slider.initialValue = slider.value;
      }

      this.dialog = false;
      this.buttonDisabled = true;
      this.buttonTimeout = setTimeout(
        () => (this.buttonDisabled = false),
        5000
      );
    },

    async loadSlidersFromMeta() {
      this.sensorSliders = [];
      if (!this.module.meta || this.module.meta.length == 0) {
        this.sensorSliders.push({
          tag: "Node",
          tagKey: "Sensor",
          value: 50,
          initialValue: 50,
          hint: "Text",
          min: 0,
          max: 100,
        });
        return;
      }

      let sliderMetas = this.module.meta.filter(
        (m) => m.key == Meta.Enum.VALUESLIDER
      );

      for (let meta of sliderMetas) {
        // deconstruct content
        let content = meta.content.split(";");

        let value = (content[2] - content[1]) / 2;
        let tagName = meta.value;
        let keyName = meta.valueMatch;
        if (!this.fakeData) {
          await this.getCurrentTagdata({
            tagId: meta.value,
            tagKey: meta.valueMatch,
          });
          if (this.currentTagData) {
            value = this.currentTagData.value;
            tagName = this.currentTagData.tag.name;
            if (this.currentTagData.label) keyName = this.currentTagData.label;
          }
        }

        this.sensorSliders.push({
          tag: meta.value,
          tagName: tagName,
          key: meta.valueMatch,
          tagKey: keyName,
          value: value,
          initialValue: value,
          hint: content[0],
          min: content[1],
          max: content[2],
        });
      }
    },
  },
  computed: {
    ...mapState("tagData", ["currentTagData"]),
  },

  async created() {
    this.loadSlidersFromMeta();
    //
  },

  beforeDestroy() {
    this.buttonDisabled = false;
    clearTimeout(this.buttonTimeout);
  },

  watch: {
    module() {
      if (this.fakeData && this.module.meta && this.module.meta.length > 0) {
        this.loadSlidersFromMeta();
      }
    },
  },
};
</script>

<style></style>
