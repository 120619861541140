<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="width ? `${width}px` : '400px'"
  >
    <v-card>
      <slot></slot>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close()">
          {{ !closeTitle ? $t("common.cancel") : closeTitle }}
        </v-btn>
        <v-btn color="blue darken-1" text @click="accept()">
          {{ !actionTitle ? $t("common.save") : actionTitle }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SoDialog",

  props: {
    dialog: { required: true, type: Boolean },
    closeTitle: { default: null },
    actionTitle: { default: null },
    width: { default: 400, type: Number },
  },

  model: {
    prop: "dialog",
  },

  methods: {
    accept() {
      this.$emit("accept", true);
      this.$emit("input", false);
    },

    close() {
      this.$emit("input", false);
    },
  },
};
</script>