var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"pb-4",style:('width: 100% !important; max-height: 100%; overflow-y: scroll; overflow: hidden;' +
    (!_vm.fakeData
      ? ' height: 100% !important;'
      : ' height: 400px !important; ') +
    (_vm.soMetaBackgroundColorCard
      ? 'background-color: ' + _vm.soMetaBackgroundColorCard
      : ''))},[_c('v-toolbar',{staticStyle:{"background":"transparent"},attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',{style:(_vm.soMetaTextColorTitle ? 'color: ' + _vm.soMetaTextColorTitle : '')},[_vm._v(_vm._s(_vm.module.title))])],1),(_vm.opts.series.length > 0)?_c('e-chart',{staticClass:"pb-3 px-8",staticStyle:{"width":"100%","max-width":"100%","min-height":"100%","max-height":"100%"},attrs:{"option":_vm.opts,"autoresize":true}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }