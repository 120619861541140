<template>
  <v-container class="d-none d-md-block preview-container elevation-2" fluid>
    <span style="display: fixed; font-size: 1.6rem">{{
      $t("common.preview")
    }}</span>
    <v-row>
      <v-col cols="12" :sm="12" :md="12">
        <ChartPreview
          v-if="type == ModuleTypes.CHART || type == ModuleTypes.MULTICHART"
          :fakeData="true"
          :module="{ title: title, meta: meta }"
          :settingsEnabled="false"
        />
        <TrendChartPreview
          v-if="type == ModuleTypes.TRENDCHART"
          :fakeData="true"
          :module="{ title: title, meta: meta, tag: previewTag, key: tagKey }"
        />
        <GaugePreview
          v-if="type == ModuleTypes.GAUGE"
          :module="{ title: title, meta: meta }"
          :fakeData="true"
          :settingsEnabled="false"
        />
        <ModuleSingle
          v-if="type == ModuleTypes.SINGLE"
          :module="{ title: title, meta: meta }"
          :fakeData="true"
        />
        <TextCard
          v-if="type == ModuleTypes.TEXT"
          :label="title"
          :value="content"
          :module="{ meta: meta }"
          :loading="false"
        />
        <TagMap v-if="type == ModuleTypes.MAP" />
        <ModImageMap
          v-if="type == ModuleTypes.IMAGEMAP"
          :module="{ title: title, meta: meta }"
          :fakeData="true"
        />
        <ModIFrame
          v-if="type == ModuleTypes.IFRAME"
          :module="{ title: title, meta: meta }"
          :fakeData="true"
          src="https://sensor-online.se"
        />
        <v-img v-if="type == ModuleTypes.IMAGE" src="missing-img.png" />

        <ModSMHI
          v-if="type == ModuleTypes.WEATHER"
          :module="{
            tag: {
              name: 'Sensor Skövde',
              latitude: '58.39027820',
              longitude: '13.84612080',
            },
            meta: meta,
          }"
          :fakeData="true"
        />
        <RawTable
          v-if="type == ModuleTypes.RAWTABLE && deveui != ''"
          :module="{
            tag: { name: 'RawTable Preview', deveui: deveui },
            meta: meta,
          }"
          :fakeData="true"
        />

        <ModFunctionButton
          v-if="type == ModuleTypes.FUNCTIONBUTTON"
          :module="{ title: title, meta: meta }"
          :fakeData="true"
        />

        <SensorOverviewList
          v-if="type == ModuleTypes.SENSOROVERVIEW"
          :module="{ title: title, meta: meta }"
          :fakeData="true"
        />

        <TiltChart
          v-if="type == ModuleTypes.TILTCHART"
          :module="{
            title: title,
            tag: { name: $t('common.sensorName') },
            meta: meta,
          }"
          :fakeData="true"
        />

        <InfoCard
          v-if="type == ModuleTypes.INFOCARD && deveui != ''"
          :module="{
            title: title,
            content: content,
            tag: { name: $t('common.sensorName'), deveui: deveui },
            meta: meta,
          }"
          :fakeData="true"
        />

        <FillIndicator
          v-if="type == ModuleTypes.FILLINDICATOR"
          :fakeData="true"
          :module="{ title: title, meta: meta }"
        />

        <ControlSliders
          v-if="type == ModuleTypes.CONTROLSLIDERS"
          :fakeData="true"
          :module="{ title: title, meta: meta }"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ChartPreview from "@/components/chart/Multichart";
import GaugePreview from "@/components/chart/Gauge";
import TrendChartPreview from "@/components/chart/TrendChart";
import ModuleSingle from "@/components/common/ModuleSingle";
import TextCard from "@/components/common/TextCard";
import TagMap from "@/components/TagMap.vue";
import ModIFrame from "@/components/common/ModIFrame";
import ModImageMap from "@/components/leaflet/ModImageMap";
import ModSMHI from "@/components/common/ModSMHI";
import RawTable from "@/components/tagdata/RawTable";
import ModFunctionButton from "@/components/common/ModFunctionButton";
import SensorOverviewList from "@/components/common/SensorOverviewList";
import InfoCard from "@/components/common/InfoCard";
import TiltChart from "@/components/chart/TiltChart.vue";
import FillIndicator from "@/components/common/FillIndicator";
import ControlSliders from "@/components/common/ControlSliders";

import CsharpEnum from "@/_helpers/CsharpEnum";
import { mapState, mapActions } from "vuex";

export default {
  name: "ModulePreview",
  props: {
    type: {
      default: 0,
      required: true,
    },

    sizeSm: {
      default: 12,
    },

    sizeMd: {
      default: 12,
    },

    title: {
      default: "Title",
    },

    content: {
      default: "",
    },

    deveui: {
      default: "",
    },

    tagKey: {
      default: "",
    },

    meta: {},
  },

  data() {
    return {
      previewTag: undefined,
    };
  },

  computed: {
    ...mapState("tag", ["currentTag"]),
    ModuleTypes() {
      return CsharpEnum.ModuleType;
    },
  },

  methods: {
    ...mapActions("tag", ["getTagAsync"]),
  },

  async created() {
    await this.getTagAsync(this.deveui);
    if (this.currentTag != undefined) this.previewTag = this.currentTag.tag;
  },

  components: {
    ChartPreview,
    TrendChartPreview,
    GaugePreview,
    ModuleSingle,
    TextCard,
    TagMap,
    ModImageMap,
    ModIFrame,
    ModSMHI,
    RawTable,
    ModFunctionButton,
    SensorOverviewList,
    InfoCard,
    TiltChart,
    FillIndicator,
    ControlSliders,
  },

  watch: {
    async deveui(v) {
      if (v != undefined || v != null) await this.getTagAsync(v);
      if (this.currentTag != undefined) this.previewTag = this.currentTag.tag;
    },
  },
};
</script>

<style>
.preview-container {
  border-radius: 5px;
  min-height: 300px;
}

.preview-spacing {
  background-color: rgba(255, 208, 0, 0.1);
}
</style>
