<template>
  <div>
    <v-speed-dial
      v-model="fab"
      right
      bottom
      color="primary"
      direction="top"
      fixed
      style="z-index: 201"
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="primary" dark fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-settings</v-icon>
        </v-btn>
      </template>

      <v-btn
        @click="editMode"
        fab
        dark
        small
        color="yellow accent-4"
        v-if="permitted('DashboardModule.Update')"
      >
        <v-icon v-if="!isMode(mode, dMode.EDIT)">mdi-pencil</v-icon>
        <v-icon v-else>mdi-close</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="success"
        @click="dialog = true"
        v-if="permitted('DashboardModule.Create')"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <v-btn fab dark small color="accent dark-3" @click="chooseDashboard">
        <v-icon>mdi-view-dashboard</v-icon>
      </v-btn>
    </v-speed-dial>

    <ModuleCreate v-on:close-dialog="closeCreate" :dialog="dialog" />
  </div>
</template>

<script>
import Enum from "@/_helpers/Enum";
import ModuleCreate from "@/components/common/ModuleCreate";

import { Roles } from "@/_helpers/Role";

export default {
  name: "ModuleFab",

  props: ["mode"],

  data() {
    return { fab: false, dialog: false };
  },

  methods: {
    editMode() {
      let _mode =
        this.mode === Enum.DashboardMode.EDIT
          ? Enum.DashboardMode.DEFAULT
          : Enum.DashboardMode.EDIT;
      this.$emit("mode-change", _mode);
    },

    closeCreate() {
      this.dialog = false;
      this.$emit("create-done");
    },

    isMode(currMode, targetMode) {
      return currMode === targetMode;
    },

    chooseDashboard() {
      this.$router.push("/dashboard");
    },
  },
  computed: {
    dMode() {
      return Enum.DashboardMode;
    },
    Roles() {
      return Roles;
    },
  },

  components: {
    ModuleCreate,
  },
};
</script>
