<template>
  <div
    :style="dashboardContainerType(this.dashboardType)"
    style="height: 100%; max-height: 100% !important"
  >
    <v-toolbar dense flat>
      <v-btn icon fab @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <template-dashboard v-if="fetched" :tags="tags" not-form />
    <v-progress-circular v-else />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { templateDashboardMixin } from "../../_helpers/templateDashboardMixin";
import TemplateDashboard from "./TemplateDashboard.vue";

export default {
  name: "TemplateDashboardContainer",
  components: { TemplateDashboard },
  mixins: [templateDashboardMixin],
  props: {
    templateDashboardId: {
      type: String,
      default: undefined,
    },

    tags: {
      type: Array,
      default: () => [],
    },

    dashboardType: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      fetched: false,
    };
  },

  methods: {
    ...mapActions("templateDashboard", ["getDashboardWidgets"]),

    async fetch() {
      this.fetched = false;
      if (
        this.templateDashboardId === undefined ||
        this.templateDashboardId === ""
      ) {
        this.fetched = true;
        return;
      }

      // Get the template dashboard with widgets
      await this.getDashboardWidgets(this.templateDashboardId);

      // TODO: Get the meta data for tags that are needed

      this.fetched = true;
    },
  },

  async created() {
    await this.fetch();
  },

  watch: {
    async templateDashboardId() {
      // Check for empty or null is made in fetch function
      await this.fetch();
    },
  },
};
</script>