<template>
  <div style="height: 100%; width: 100%">
    <TextCard
      v-if="CModuleType.TEXT === localItem.type"
      :label="localItem.title"
      :value="localItem.content"
      :module="localItem"
      :loading="false"
    />

    <Multichart
      v-if="
        CModuleType.MULTICHART === localItem.type ||
        CModuleType.CHART === localItem.type
      "
      :module="localItem"
      :timerTick="timerTick"
    />

    <TagMap
      v-if="CModuleType.MAP === localItem.type"
      :module="localItem"
      :mode="mode"
    />
    <Gauge
      v-if="CModuleType.GAUGE === localItem.type"
      :module="localItem"
      :timerTick="timerTick"
    />
    <ModImage v-if="CModuleType.IMAGE === localItem.type" :module="localItem" />
    <ModImageMap
      v-if="CModuleType.IMAGEMAP === localItem.type"
      :mode="mode"
      :module="localItem"
      :timerTick="timerTick"
    />
    <ModIFrame
      v-if="CModuleType.IFRAME === localItem.type"
      :src="localItem.content"
      :module="localItem"
    />
    <ModuleSingle
      v-if="CModuleType.SINGLE == localItem.type"
      :module="localItem"
      :timerTick="timerTick"
    />
    <ModFunctionButton
      v-if="CModuleType.FUNCTIONBUTTON == localItem.type"
      :module="localItem"
    />
    <ModSMHI
      v-if="CModuleType.WEATHER === localItem.type"
      :module="localItem"
      :timerTick="timerTick"
    />
    <RawTable
      v-if="CModuleType.RAWTABLE === localItem.type"
      :module="localItem"
      :timerTick="timerTick"
    />
    <InfoCard
      v-if="CModuleType.INFOCARD === localItem.type"
      :module="localItem"
      :timerTick="timerTick"
    />

    <TrendChart
      v-if="CModuleType.TRENDCHART === localItem.type"
      :settingsEnabled="true"
      :tag="localItem.tag"
      :module="localItem"
      :timerTick="timerTick"
    />

    <SensorOverviewList
      v-if="CModuleType.SENSOROVERVIEW === localItem.type"
      :settingsEnabled="true"
      :tag="localItem.tag"
      :module="localItem"
      :timerTick="timerTick"
    />

    <TiltChart
      v-if="CModuleType.TILTCHART === localItem.type"
      :module="localItem"
      :timerTick="timerTick"
      :settingsEnabled="true"
    />

    <ModuleCopy
      v-if="mode == MType.EDIT"
      :module="localItem"
      v-on:update="updateEvent"
    />

    <ModuleEdit
      v-if="mode == MType.EDIT"
      :module="localItem"
      v-on:update-event="updateEvent"
    />

    <FillIndicator
      v-if="CModuleType.FILLINDICATOR === localItem.type"
      :module="localItem"
      :timerTick="timerTick"
    />

    <ControlSliders
      v-if="CModuleType.CONTROLSLIDERS === localItem.type"
      :module="localItem"
    />

    <cam-online-module
      v-if="CModuleType.CAMONLINE === localItem.type"
      :module="localItem"
    />
  </div>
</template>

<script>
import CsharpEnum from "@/_helpers/CsharpEnum";
import Enum from "@/_helpers/Enum";
import TextCard from "@/components/common/TextCard";
import InfoCard from "@/components/common/InfoCard";
import Gauge from "@/components/chart/Gauge";
import ModImage from "@/components/common/ModImage";
import ModImageMap from "@/components/leaflet/ModImageMap";
import ModuleEdit from "@/components/common/ModuleEdit";
import ModIFrame from "@/components/common/ModIFrame";
import ModSMHI from "@/components/common/ModSMHI";
import RawTable from "@/components/tagdata/RawTable";
import ModuleSingle from "@/components/common/ModuleSingle";
import ModFunctionButton from "@/components/common/ModFunctionButton";
import TagMap from "@/components/TagMap";
import Multichart from "@/components/chart/Multichart";
import TrendChart from "@/components/chart/TrendChart";
import SensorOverviewList from "@/components/common/SensorOverviewList";
import TiltChart from "@/components/chart/TiltChart.vue";
import ModuleCopy from "@/components/common/ModuleCopy";
import FillIndicator from "@/components/common/FillIndicator";
import ControlSliders from "@/components/common/ControlSliders";
import CamOnlineModule from "../camonline/CamOnlineModule.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "Module",

  props: {
    item: Object,
    mode: Number,
    timerTick: Number,
  },

  data() {
    return {
      active: false,
      dialog: false,
      localItem: {},
    };
  },

  computed: {
    ...mapState("modules", { modules: "modules", moduleStatus: "status" }),
    CModuleType() {
      return CsharpEnum.ModuleType;
    },

    MType() {
      return Enum.DashboardMode;
    },
  },

  methods: {
    ...mapActions("modules", { getModules: "getModules" }),

    updateEvent(val) {
      this.$emit("update-event", val);
    },
  },

  created() {
    let itemType = this.item.type;
    this.localItem = this.item;

    switch (itemType) {
      case CsharpEnum.ModuleType.CHART:
        if (this.localItem.tag) this.localItem.tag.key = this.item.key;
        break;
      case CsharpEnum.ModuleType.GAUGE:
        break;
      case CsharpEnum.ModuleType.TEXT:
        break;
      case CsharpEnum.ModuleType.IMAGE:
        break;
      case CsharpEnum.ModuleType.MAP:
        break;
      case CsharpEnum.ModuleType.COMPANYINFO:
        break;
      case CsharpEnum.ModuleType.IFRAME:
        break;
    }
  },

  watch: {
    item(newVal) {
      this.localItem = newVal;
    },
  },

  components: {
    CamOnlineModule,
    ControlSliders,
    FillIndicator,
    Gauge,
    InfoCard,
    ModFunctionButton,
    ModIFrame,
    ModImage,
    ModImageMap,
    ModSMHI,
    ModuleCopy,
    ModuleEdit,
    ModuleSingle,
    Multichart,
    RawTable,
    SensorOverviewList,
    TagMap,
    TextCard,
    TiltChart,
    TrendChart,
  },
};
</script>

<style></style>
