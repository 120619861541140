var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-sheet',_vm._g(_vm._b({class:`${_vm.addMargin ? 'ma-2' : ''} ${
        _vm.selected ? 'white--text' : 'black--text'
      }`,style:((_vm.disable ? 'cursor: pointer' : 'cursor:pointer') + ' ;' + _vm.sheetStyle),attrs:{"color":_vm.selected ? `${_vm.sheetColor} darken-4` : `${_vm.sheetColor} lighten-2`,"rounded":"","elevation":"4","height":_vm.small ? 40 : 70,"outlined":_vm.selected ? true : false},on:{"click":_vm.sheetClick}},'v-sheet',attrs,false),on),[_c('div',{staticClass:"mt-auto align-center justify-start d-flex px-2 fill-height"},[_c('v-icon',{attrs:{"color":_vm.selected ? `${_vm.sheetColor} lighten-4` : `${_vm.sheetColor}`,"large":!_vm.small,"small":_vm.small}},[_vm._v(_vm._s(_vm.iconKey)+" ")]),_c('div',{staticClass:"d-flex justify-space-evenly flex-column align-start",staticStyle:{"max-width":"75%"}},[_c('span',{class:'condition-type-font ml-3 my-0' +
              (_vm.selected ? ' white--text' : '') +
              (_vm.subLabel ? ' text-left' : ''),style:(`font-size: ${_vm.fontSize}; line-height: ${_vm.fontSize}; font-weight: bolder; width: 100%;`)},[_vm._v(_vm._s(_vm.itemLabel === undefined ? _vm.label : _vm.item[_vm.itemLabel].toUpperCase())+" ")]),(_vm.subLabel)?_c('span',{class:'condition-type-font ml-3 my-0 text-truncate text-left' +
              (_vm.selected ? ' white--text' : ''),style:(`font-size: ${
              _vm.subLabelFontSize ?? '.6rem'
            }; line-height: ${
              _vm.subLabelFontSize ?? '.6rem'
            }; font-weight: 100; width: 100%`)},[_vm._v(_vm._s(_vm.subLabel)+" ")]):_vm._e()]),(_vm.deleteable)?_c('v-icon',{staticClass:"ml-3",attrs:{"color":"error","large":!_vm.small,"small":_vm.small},on:{"click":() => _vm.$emit('delete', _vm.item)}},[_vm._v("mdi-delete ")]):_vm._e()],1)])]}}])},[_c('span',[_vm._v(_vm._s(_vm.itemLabel === undefined ? _vm.label : _vm.item[_vm.itemLabel].toUpperCase()))])])
}
var staticRenderFns = []

export { render, staticRenderFns }