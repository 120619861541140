<template>
  <v-progress-linear
    color="blue"
    height="10"
    :value="progressValue"
    striped
  ></v-progress-linear>
</template>

<script>
export default {
  name: "TimedProgress",
  props: {
    time: {
      default: 15,
    },
  },

  data() {
    return {
      interval: null,
      progressValue: 0,
    };
  },

  methods: {
    updateProgress() {
      if (this.progressValue >= 100) {
        this.progressValue = 0;
        this.$emit("event-update");
        return;
      }

      this.progressValue += 100 / this.time;
    },
  },

  mounted() {
    this.interval = setInterval(this.updateProgress, 1000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>