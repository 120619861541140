<template>
  <div :style="`background-color:${color}`" class="palette-chip">
    <v-row style="justify-content: flex-end">
      <v-col cols="12" xs="10" md="10" v-if="!cardMode">
        <v-autocomplete
          v-if="isInType(type, [ModuleTypes.CHART, ModuleTypes.MULTICHART])"
          :label="color"
          autocomplete="off"
          :dark="false"
          :items="fieldNames"
          item-text="name"
          class="ps-2"
          v-model="fieldName"
          @change="constructMeta"
          return-object
        ></v-autocomplete>
        <v-slider
          v-if="isInType(type, [ModuleTypes.GAUGE, ModuleTypes.FILLINDICATOR])"
          v-model="range"
          max="100"
          min="0"
          step="1"
          color="accent"
          :thumb-label="true"
          thumb-size="xs"
          :messages="color"
          @change="constructMeta"
        ></v-slider>

        <v-autocomplete
          v-if="isInType(type, [ModuleTypes.TEXT, ModuleTypes.SINGLE])"
          :label="color"
          autocomplete="off"
          :dark="false"
          :items="fieldNames"
          item-text="name"
          item-value="value"
          class="ps-2"
          v-model="fieldName"
          @change="constructMeta"
          return-object
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" xs="10" md="10" v-else>
        <v-autocomplete
          :label="color"
          autocomplete="off"
          :dark="false"
          :items="colorFields"
          item-text="name"
          item-value="value"
          class="ps-2"
          v-model="fieldName"
          @change="constructColorMeta"
        ></v-autocomplete>
        <v-slider
          v-model="range"
          max="100"
          min="0"
          step="5"
          color="accent"
          :thumb-label="true"
          thumb-size="xs"
          :messages="$t('module.meta.opacity')"
          @change="constructColorMeta"
        >
          <template v-slot:thumb-label="{ value }">
            {{ value.toString() + "%" }}
          </template>
        </v-slider>
      </v-col>
      <v-col cols="12" xs="2" md="2" style="margin: 0; padding: 0">
        <v-tooltip bottom v-if="colorFields.length > 0">
          <template v-slot:activator="{ on }">
            <v-icon
              color="accent"
              style="margin: 1rem auto"
              v-on="on"
              @click="switchMode()"
              >mdi-format-color-fill</v-icon
            >
          </template>
          <span>
            {{
              cardMode
                ? $t("module.meta.paletteToModuleMode")
                : $t("module.meta.paletteToCardMode")
            }}
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              color="accent"
              style="margin: 1rem auto"
              v-on="on"
              @click="$emit('remove')"
              >mdi-delete</v-icon
            >
          </template>
          <span>
            {{ $t("common.delete") }}
          </span>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ModuleTypesMixin from "@/_helpers/ModuleTypeHelper.js";

export default {
  name: "PaletteChip",
  mixins: [ModuleTypesMixin],
  props: {
    type: {
      default: null,
    },

    metas: {
      type: Array,
    },

    color: {
      default: "#FFF",
    },

    meta: {
      default: {},
    },

    localMeta: {
      default: [],
    },

    fieldNames: {
      default: [],
    },

    colorFields: {
      default() {
        return [];
      },
    },

    hasModuleStyle: {
      default: false,
    },
  },

  data() {
    return {
      range: 100,
      cardMode: false,
      fieldName: null,
    };
  },

  async created() {
    this.getTags();
    if (this.meta.value) {
      this.fieldName = { name: this.meta.value };
    }

    if (this.meta.valueMatch) {
      this.range = this.meta.valueMatch;
    }

    if (this.isInCardMode || !this.hasModuleStyle) this.cardMode = true;

    if (this.isInCardMode) this.fieldName = this.meta.key;
  },

  computed: {
    ...mapState("tag", ["tags"]),

    isInCardMode() {
      return ModuleTypesMixin.colorFieldsMeta
        .map((e) => e.value)
        .includes(this.meta.key);
    },
  },

  methods: {
    ...mapActions("tag", ["getTags"]),

    constructMeta() {
      if (
        this.isInType(this.type, [
          this.ModuleTypes.GAUGE,
          this.ModuleTypes.FILLINDICATOR,
        ])
      ) {
        // range 0 has min range 1 has max
        this.$emit("update", {
          key: this.meta.key,
          content: this.color,
          valueMatch: this.range,
        });
      } else if (
        this.isInType(this.type, [
          this.ModuleTypes.CHART,
          this.ModuleTypes.MULTICHART,
        ])
      ) {
        this.$emit("update", {
          key: this.meta.key,
          content: this.color,
          value: this.fieldName.name,
          valueMatch: this.fieldName.deveui + " - " + this.fieldName.key,
        });
      } else if (
        this.isInType(this.type, [
          this.ModuleTypes.TEXT,
          this.ModuleTypes.SINGLE,
        ])
      ) {
        // We put value as well for the reactivity when editing
        this.$emit("update", {
          key: this.fieldName.value,
          content: this.color,
          value: this.fieldName.value,
        });
      }
    },

    constructColorMeta() {
      let opacity = Math.round(this.range * 2.55).toString(16);
      if (opacity.length == 1) opacity = "0" + opacity;

      this.$emit("update", {
        key: this.fieldName,
        content: this.color.substring(0, 7) + opacity,
        valueMatch: this.range,
      });
    },

    switchMode() {
      this.fieldName = { name: "" };
      this.cardMode = !this.cardMode;
    },
  },

  watch: {},
};
</script>

<style scoped>
.palette-chip {
  max-width: 230px;
  min-width: 230px;
  padding: 0.5rem;
  display: inline-block;
  border-radius: 0.5rem;
}
</style>