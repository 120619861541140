<template>
  <div class="overlay" style="right: 0px; bottom: 0px; position: absolute">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-top-transition"
      persistent
    >
      <template v-slot:activator="{ on }">
        <v-btn class="dashboard-floaty" color="primary" fab small v-on="on">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>

      <v-toolbar dark color="primary">
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("module.edit") }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card>
        <v-container v-if="typeSelected" fluid>
          <v-row>
            <v-col cols="12" sm="0" md="6">
              <ModulePreview
                v-if="
                  type != ModuleTypes.MAP &&
                  styleMetas &&
                  Object.keys(form).length > 0
                "
                :type="type"
                :title="form.title"
                :content="form.content"
                :deveui="form.deveui"
                :meta="form.metas"
                :tagKey="form.tagKey"
              />
              <style-picker-module
                :type="type"
                :metas="form.metas"
                v-on:update="updateStyleMeta"
                v-if="form.metas && hasStyle"
              />
            </v-col>

            <v-col
              cols="12"
              sm="12"
              :md="hasStyle || type == ModuleTypes.CONTROLSLIDERS ? 6 : 12"
            >
              <module-form
                :headline="$t('module.edit')"
                :edit="true"
                :type="type"
                :module="module"
                :styleMetas="styleMetas"
                v-on:update-form="updateFrom"
                v-on:close-dialog-event="close"
                v-on:update-event="(val) => $emit('update-event', val)"
              />
            </v-col>
          </v-row>
          <v-row v-if="type == ModuleTypes.CONTROLSLIDERS">
            <v-col>
              <control-slider-picker
                :metas="module.meta"
                v-on:update="setMeta"
              />
            </v-col>
          </v-row>
        </v-container>

        <v-col sm="12" md="12" align="center" v-else>
          <ModuleTypeSelector v-on:update="selectType" />
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ModuleTypeSelector from "@/components/tag/ModuleTypeSelector";
import ModulePreview from "@/components/common/ModulePreview";
import StylePickerModule from "@/components/common/StylePickerModule";
import ControlSliderPicker from "@/components/common/ControlSliderPicker";
import ModuleTypes from "@/_helpers/ModuleTypeHelper.js";
import ModuleForm from "@/components/module/ModuleForm.vue";

export default {
  name: "ModuleEdit",

  props: ["module"],
  mixins: [ModuleTypes],

  data() {
    return {
      dialog: false,
      typeSelected: true,

      meta: {
        key: null,
        valueMatch: null,
        value: null,
        deveui: null,
      },

      form: {},

      type: null,

      syncChart: false,
      styleMetas: [],

      moduleTypes: ModuleTypes.types,
    };
  },

  methods: {
    ...mapActions("tag", ["getKeysForTag"]),
    ...mapActions("tagData", { getTdKeys: "getTagDataKeys" }),

    updateFrom(val) {
      this.form = val;
    },

    close() {
      this.dialog = false;
    },

    updateStyleMeta(styleMetas) {
      // Set stylemeta reactively
      this.styleMetas = styleMetas.slice(0, styleMetas.length);

      if (this.styleMetas) {
        this.form.metas = this.form.metas.filter(
          (m) => m.key != null && !m.key.startsWith("so_style_meta")
        );

        if (this.styleMetas.length > 0)
          this.form.metas = this.form.metas.concat(this.styleMetas);
      }
    },

    setMeta(meta) {
      if (meta && meta.length > 0) {
        this.form.metas = meta;
      }
    },

    selectType(type) {
      this.metas = [];
      this.styleMetas = [];
      this.type = type;
      this.typeSelected = true;
    },
  },

  async mounted() {},

  computed: {
    ...mapState("tag", { tagStatus: "status", tags: "tags" }),
    ...mapState("dashboards", ["dashboards", "currentDashboard"]),
    ...mapState("modules", { currentModule: "currentModule" }),
    ...mapState("scripts", { scriptStatus: "status", scripts: "scripts" }),

    hasStyle() {
      let moduleType = this.moduleTypes.find((e) => e.value == this.type);

      if (!moduleType) return null;

      if (
        moduleType.hasOwnStyle ||
        moduleType.colorFieldsUsed.find((e) => e == true)
      )
        return true;
      return false;
    },
  },

  components: {
    ModuleTypeSelector,
    ModulePreview,
    "style-picker-module": StylePickerModule,
    ControlSliderPicker,
    ModuleForm,
  },

  watch: {
    // eslint-disable-next-line
    deveui(val, oldVal) {
      if (val != oldVal) {
        this.getKeysForTag({ tagId: val });
        if (oldVal) this.metas = [];
      }
    },

    dialog(val) {
      if (val) {
        this.type = this.module.type;
        if (
          this.deveui !== null &&
          this.deveui !== undefined &&
          this.deveui !== ""
        ) {
          this.getKeysForTag({ tagId: this.deveui });
        } else {
          this.getTdKeys();
        }
      }
    },
  },
};
</script>

<style scoped>
.dashboard-floaty {
  position: absolute;
  margin: 0 auto;
  z-index: 20;
  transform: translateX(-60px) translateY(-60px);
}

.preserve-spacing {
  white-space: pre-wrap;
}
</style>
