<template>
  <div class="d-flex justify-space-between align-center">
    <v-menu
      ref="menu"
      v-model="dateMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="datePart"
          :label="$t('common.dateLabel')"
          prepend-icon="mdi-clock-time-four-outline"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>

      <v-date-picker header-color="accent" v-model="datePart"></v-date-picker>
    </v-menu>

    <v-menu
      ref="menu"
      v-model="timeMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="timePart"
          :label="$t('common.timeLabel')"
          prepend-icon="mdi-clock-time-four-outline"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-time-picker
        width="290"
        header-color="accent"
        v-model="timePart"
        format="24hr"
        scrollable
      ></v-time-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "SoDateTimePicker",

  props: {
    value: {
      type: Date,
      default: new Date(),
    },

    format: {
      default: "yyyy-MM-dd HH:mm:ss",
    },
  },

  model: {
    prop: "value",
  },

  data() {
    return {
      datePart: undefined,
      timePart: undefined,

      timeMenu: false,
      dateMenu: false,
    };
  },

  created() {
    // 1. Deconstruct datetime if provided
    var dateTime = this.humanDate(this.value, "yyyy-MM-dd HH:mm");
    var parts = dateTime.split(" ");
    if (parts.length > 1) {
      this.datePart = parts[0];
      this.timePart = parts[1];
    }
  },

  watch: {
    timePart() {
      // Set the time part of the date
      this.$emit("input", new Date(`${this.datePart} ${this.timePart}`));
    },

    datePart() {
      // Set the time part of the date
      this.$emit("input", new Date(`${this.datePart} ${this.timePart}`));
    },
  },
};
</script>

