<template>
  <div v-if="typeHasMeta()">
    <v-card-text class="text-center">
      <span class="title font-weight-light">{{
        $t("module.metas").toUpperCase()
      }}</span>
      <v-divider />
      <v-select
        :items="metaKeysFiltered"
        :label="$t('module.meta.label')"
        item-text="name"
        item-value="key"
        v-model="meta.key"
        prepend-icon="mdi-plus"
        @change="metaChanged"
      ></v-select>

      <v-text-field
        :label="metaFieldLabel(0)"
        prepend-icon="mdi-alpha-v"
        type="text"
        v-model="meta.valueMatch"
        v-if="metaValueMatchField"
      ></v-text-field>

      <v-text-field
        :label="metaFieldLabel(1)"
        prepend-icon="mdi-alpha-n"
        type="text"
        v-model="meta.value"
        v-if="metaValueField"
      ></v-text-field>

      <v-select
        :items="metaIcons"
        :label="$t('module.meta.icon')"
        v-model="meta.value"
        prepend-icon="mdi-auto-fix"
        v-if="metaIconField"
      >
        <template slot="item" slot-scope="data">
          <v-icon small>{{ data.item }}</v-icon>
        </template>
        <template slot="selection" slot-scope="data">
          <v-icon medium>{{ data.item }}</v-icon>
        </template>
      </v-select>

      <v-autocomplete
        autocomplete="off"
        :loading="tagStatus.loading"
        :label="$t('alarm.fields.tag')"
        v-model="meta.value"
        :items="tags"
        required
        item-text="name"
        item-value="deveui"
        prepend-icon="mdi-cellphone-link"
        clearable
        v-if="metaTagField"
        @change="deveuiChanged"
      ></v-autocomplete>

      <v-autocomplete
        :disabled="tagStatus.loading || keyDisabled"
        :loading="tagDataStatus.loading"
        :label="$t('alarm.fields.tagDataKey')"
        v-model="meta.valueMatch"
        :items="keys"
        required
        item-text="label"
        item-value="key"
        prepend-icon="mdi-key"
        autocomplete="off"
        clearable
        v-if="
          (meta.key == 'so_deveui_key' ||
            meta.key == 'so_tagkey' ||
            meta.key == 'so_tagkey_x' ||
            meta.key == 'so_tagkey_y' ||
            meta.key == 'so_tagkey_x_max' ||
            meta.key == 'so_tagkey_y_max') &
          isType(
            [MType.MULTICHART, MType.INFOCARD, MType.TILTCHART, MType.SINGLE],
            type
          )
        "
      ></v-autocomplete>

      <v-autocomplete
        :label="$t('module.meta.fields.alarm_id')"
        v-model="meta.valueMatch"
        :items="alarms"
        required
        item-text="name"
        item-value="alarmId"
        prepend-icon="mdi-alarm"
        autocomplete="off"
        clearable
        v-if="meta.key == 'so_alarm_id'"
      ></v-autocomplete>

      <v-autocomplete
        autocomplete="off"
        :loading="scriptStatus.loading"
        :label="$t('script.name')"
        v-model="meta.value"
        :items="calledScripts"
        item-text="name"
        item-value="scriptId"
        prepend-icon="mdi-cellphone-link"
        clearable
        v-if="meta.key == 'so_script_id'"
      ></v-autocomplete>

      <!-- Dashboard ID Meta -->
      <v-autocomplete
        autocomplete="off"
        :label="$t('dashboard.name')"
        v-model="meta.value"
        :items="dashboards"
        item-text="name"
        item-value="dashboardId"
        prepend-icon="mdi-view-dashboard-variant"
        clearable
        v-if="meta.key == 'so_dashboard_id'"
      ></v-autocomplete>

      <v-switch
        v-model="meta.value"
        v-if="meta.key == 'so_force_colors'"
        :label="$t('module.meta.desc.forceColors')"
      ></v-switch>

      <v-switch
        v-model="meta.value"
        v-if="meta.key == 'so_sync_y'"
        :label="$t('module.meta.fields.sync_y')"
      ></v-switch>

      <v-switch
        v-model="meta.value"
        v-if="meta.key == 'so_zoom_slider'"
        :label="$t('module.meta.fields.zoom_slider')"
      ></v-switch>

      <v-switch
        v-model="meta.value"
        v-if="meta.key == 'so_digital'"
        :label="$t('module.meta.fields.digital')"
      ></v-switch>

      <v-switch
        v-model="meta.value"
        v-if="meta.key == 'so_ignore_body'"
        :label="$t('module.meta.fields.ignoreBody')"
      ></v-switch>

      <v-autocomplete
        autocomplete="off"
        :label="$t('module.meta.fields.chart_type')"
        :items="[
          { name: $t('module.chart.types.area'), value: 'area' },
          { name: $t('module.chart.types.line'), value: 'line' },
          { name: $t('module.chart.types.bar'), value: 'bar' },
          {
            name: $t('module.chart.types.scatter'),
            value: 'scatter',
          },
          { name: $t('module.chart.types.vertical'), value: 'vertical' },
        ]"
        item-text="name"
        item-value="value"
        prepend-icon="mdi-chart-areaspline"
        clearable
        v-model="meta.value"
        v-if="meta.key == 'so_chart_type'"
      >
      </v-autocomplete>

      <v-autocomplete
        autocomplete="off"
        :label="$t('module.meta.fields.default_timespan')"
        :items="[
          { name: $t('common.dateRange.0'), value: 'hour' },
          { name: $t('common.dateRange.1'), value: '5_hour' },
          { name: $t('common.dateRange.2'), value: '24_hour' },
          { name: $t('common.dateRange.3'), value: '7_days' },
          { name: $t('common.dateRange.4'), value: '30_days' },
        ]"
        item-text="name"
        item-value="value"
        prepend-icon="mdi-chart-areaspline"
        clearable
        v-model="meta.value"
        v-if="meta.key == 'so_default_timespan'"
      >
      </v-autocomplete>

      <v-autocomplete
        v-if="
          isType(MType.SINGLE, type) &&
          (meta.key == 'so_sufix' || meta.key == 'so_prefix')
        "
        autocomplete="off"
        :label="$t('module.meta.fields.tagkey')"
        :items="fieldNames"
        :item-text="
          (item) =>
            getFriendlyName(item.value) +
            ' - ' +
            getLabelForKey(item.value, item.valueMatch)
        "
        prepend-icon="mdi-cellphone-link"
        clearable
        v-model="sensorTag"
        return-object
      >
      </v-autocomplete>

      <div v-if="meta.key == 'so_font_style_large'">
        <v-autocomplete
          autocomplete="off"
          :label="$t('module.meta.fields.font_style_large.type')"
          :items="fontTypes"
          item-text="name"
          item-value="value"
          prepend-icon="mdi-format-color-text"
          clearable
          v-model="meta.valueMatch"
        >
        </v-autocomplete>

        <v-autocomplete
          autocomplete="off"
          :label="$t('module.meta.fields.font_style_large.size')"
          :items="fontSizes"
          prepend-icon="mdi-format-color-text"
          clearable
          v-model="meta.value"
        >
          <template v-slot:item="item">
            <span :style="'font-size: ' + item.item + 'rem'"> Text </span>
          </template>
        </v-autocomplete>

        <v-autocomplete
          autocomplete="off"
          :label="$t('module.meta.fields.font_style_large.weight')"
          :items="fontWeights"
          item-text="name"
          item-value="value"
          prepend-icon="mdi-format-color-text"
          clearable
          v-model="meta.content"
        >
          <template v-slot:item="data">
            <span :class="data.item.value">
              {{ data.item.name }}
            </span>
          </template>
        </v-autocomplete>
      </div>

      <v-btn
        color="accent"
        text
        @click="storeMeta()"
        :disabled="!canAddMeta(meta.key)"
      >
        {{ $t("module.meta.save") }}
      </v-btn>

      <v-simple-table v-if="regularMetas.length > 0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                {{ $t("module.meta.key") }}
              </th>
              <th class="text-left">
                {{ $t("module.meta.valueMatch") }}
              </th>
              <th class="text-left">
                {{ $t("module.meta.value") }}
              </th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(m, i) in regularMetas" :key="i">
              <td class="text-left">
                {{ metaLangForKey(m.key) }}
              </td>
              <td class="text-left">
                {{
                  shouldHaveFriendlyMatch(m.key)
                    ? getFriendlyMatch(m.key, m.value, m.valueMatch)
                    : m.valueMatch
                }}
              </td>
              <td class="text-left">
                {{
                  shouldHaveFriendlyName(m.key)
                    ? getFriendlyName(m.value)
                    : m.value
                }}
              </td>
              <td>
                <v-btn color="error" fab x-small @click="removeMeta(m)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Meta from "@/_helpers/ModuleMeta";
import FontMeta from "@/_helpers/FontMeta";
import CsharpEnum from "@/_helpers/CsharpEnum";

export default {
  name: "MetaForm",

  props: ["formmeta", "type", "deveui", "tagKey", "indexedTags"],

  data() {
    return {
      meta: {
        key: null,
        valueMatch: null,
        value: null,
        deveui: null,
      },

      metaValueMatchField: false,
      metaValueField: false,
      metaIconField: false,
      metaTagField: false,
      metaBoolField: false,

      metas: [],
      sensorTag: null,
      indexedKeys: [],

      hiddenMetas: [
        "so_functionbutton_downlink",
        "so_script_id",
        "so_dashboard_id",
        "so_functionbutton_onoff",
      ],
    };
  },

  methods: {
    ...mapActions("tag", ["getTags", "getKeysForTag", "getKeyLabel"]),
    ...mapActions("alarms", ["getAlarms"]),
    ...mapActions("scripts", ["getScripts", "setCurrentScript"]),
    ...mapActions("dashboards", ["getDashboards"]),

    metaFieldLabel(index) {
      return Meta.InputFields[this.meta.key].fieldsLabels[index];
    },

    typeHasMeta() {
      if (
        this.type == null ||
        this.type == undefined ||
        Meta.Widgets[this.type] == undefined ||
        Meta.Widgets[this.type].length <= 0
      )
        return false;

      return true;
    },

    isType(target, type) {
      if (!Array.isArray(target)) {
        return target === type;
      }

      return target.includes(type);
    },

    // This function is used so the user cannot add duplicates of keys that are not allowed
    // Expand this to take in to account all meta keys
    canAddMeta(key) {
      // Alarm limits and Y-synchronization are incompatible
      if (key == "so_alarm_id" || key == "so_sync_y") {
        return (
          this.metas.filter((m) => m.key == "so_alarm_id" || m.key == "sync_y")
            .length <= 0
        );
      }
      // Allow one
      if (
        key == "so_force_colors" ||
        key == "so_tagkey_x" ||
        key == "so_tagkey_y" ||
        key == "so_tagkey_x_max" ||
        key == "so_tagkey_y_max" ||
        key == "so_decimal_rounding" ||
        key == "so_script_id" ||
        key == "so_dashboard_id" ||
        key == "so_zoom_slider" ||
        key == "so_digital" ||
        key == "so_chart_type" ||
        key == "so_ignore_body" ||
        key == "so_min_value" ||
        key == "so_max_value" ||
        key == "so_axis_label" ||
        key == "so_default_timespan" ||
        key == "so_hide_trend_icon" ||
        key == "so_horizontel_single_title" ||
        key == "so_hide_node_info" ||
        key == "so_button_label" ||
        key == "so_button_label_on" ||
        key == "so_button_label_off" ||
        (key == "so_sufix" && this.type != CsharpEnum.ModuleType.SINGLE) ||
        (key == "so_prefix" && this.type != CsharpEnum.ModuleType.SINGLE) ||
        key == "so_single_sparkline"
      ) {
        return this.metas.filter((m) => m.key == key).length <= 0;
      }

      return true;
    },

    metaChanged() {
      let m = Meta.InputFields[this.meta.key];
      this.metaValueMatchField = m.fields[1];
      this.metaValueField = m.fields[0];
      this.metaIconField = m.fields[2];
      this.metaTagField = m.fields[3];
      this.metaBoolField = m.fields[4];

      if (this.meta.key == "so_script_id") {
        this.getScripts();
      } else if (this.meta.key == "so_dashboard_id") {
        this.getDashboards();
      } else if (this.meta.key == "so_alarm_id") {
        this.getAlarms();
      }

      if (m.fields[4]) {
        this.meta.value = true;
      } else this.meta.value = null;
    },

    async deveuiChanged() {
      await this.getKeysForTag({ tagId: this.meta.value });
    },

    storeMeta() {
      if (this.metaBoolField && !this.meta.value) return;

      if (
        (this.meta.key == "so_sufix" || this.meta.key == "so_prefix") &&
        this.sensorTag != null
      )
        this.metas.push({
          key: this.meta.key,
          valueMatch: this.sensorTag.valueMatch,
          value: this.meta.value,
          content: this.sensorTag.value,
        });
      else
        this.metas.push({
          key: this.meta.key,
          valueMatch: this.meta.valueMatch,
          value: this.meta.value,
          content: this.meta.content,
        });

      this.meta.valueMatch = null;
      this.meta.value = null;
      this.meta.deveui = null;
      this.meta.content = null;

      this.$emit("update-meta", this.metas);
    },

    removeMeta(m) {
      this.metas.splice(this.metas.indexOf(m), 1);

      this.$emit("update-meta", this.metas);
    },

    metaLangForKey(key) {
      for (let i = 0; i < Meta.Keys.length; i++) {
        if (Meta.Keys[i].key === key) return Meta.Keys[i].name;
      }
    },

    shouldHaveFriendlyName(key) {
      let friendlyNames = [Meta.Enum.DEVEUI];

      return friendlyNames.includes(key);
    },

    shouldHaveFriendlyMatch(key) {
      let friendlyNames = [Meta.Enum.FONT_STYLE, Meta.Enum.DEVEUI];

      return friendlyNames.includes(key);
    },

    getFriendlyName(value) {
      return this.indexedTags[value] != undefined
        ? this.indexedTags[value]
        : value;
    },

    getFriendlyMatch(meta, value, valueMatch) {
      if (Meta.Enum.DEVEUI == meta)
        return this.getLabelForKey(value, valueMatch);
      if (Meta.Enum.FONT_STYLE == meta) {
        let result = Object.values(FontMeta.types).find(
          (e) => e.value == valueMatch
        );

        return result ? result.name : valueMatch;
      }
    },

    getLabelForKey(deveui, tagkey) {
      let key = this.indexedKeys.find(
        (k) => k.deveui == deveui && k.key == tagkey
      );
      if (key) return key.label;

      return tagkey;
    },

    async indexKeys() {
      this.indexedKeys.push(...this.keys);

      let metas = this.metas.filter((m) => m.key == "so_deveui_key");
      for (let meta of metas) {
        await this.getKeysForTag({ tagId: meta.value });
        this.indexedKeys.push(...this.keys);
      }
    },
  },

  computed: {
    ...mapState("alarms", ["alarms"]),
    ...mapState("tag", {
      tagStatus: "status",
      tags: "tags",
    }),
    ...mapState("tagData", { tagDataStatus: "status", keys: "keys" }),
    ...mapState("scripts", { scriptStatus: "status", scripts: "scripts" }),
    ...mapState("dashboards", ["dashboards", "currentDashboard"]),

    fieldNames() {
      let fieldNames = this.metas.filter((m) => m.key == "so_deveui_key");

      if (this.deveui && this.tagKey)
        fieldNames.push({
          key: "so_deveui_key",
          value: this.deveui,
          valueMatch: this.tagKey,
        });
      return fieldNames;
    },

    MType() {
      return CsharpEnum.ModuleType;
    },

    metaKeys() {
      return Meta.Keys;
    },

    metaIcons() {
      return Meta.Icons;
    },

    fontWeights() {
      return FontMeta.weights;
    },

    fontSizes() {
      return FontMeta.sizes;
    },

    fontTypes() {
      let activeTypes = FontMeta.moduleUsedTypes.find(
        (t) => t.key == this.type
      );

      return Object.values(FontMeta.types).filter((t) =>
        activeTypes.value.includes(t.value)
      );
    },

    regularMetas() {
      return this.metas.filter(
        (m) =>
          m.key != null &&
          !m.key.startsWith("so_style_meta") &&
          !this.hiddenMetas.includes(m.key)
      );
    },

    metaKeysFiltered() {
      return Meta.Keys.filter(
        (m) =>
          Meta.Widgets[this.type].includes(m.key) &&
          !Meta.RegularDashboardIgnoreMeta[this.type]?.includes(m.key)
      );
    },

    keyDisabled() {
      let disabled = [CsharpEnum.ModuleType.CHART, CsharpEnum.ModuleType.GAUGE];
      if (
        this.isType(disabled, this.type) &&
        (this.deveui === "" || this.deveui === undefined || this.deveui == null)
      ) {
        return true;
      }

      return false;
    },

    calledScripts() {
      return this.scripts.filter((s) => {
        return s.type == 1;
      });
    },
  },

  async created() {
    if (this.formmeta) this.metas = this.formmeta;

    await this.indexKeys();
  },
};
</script>
