<template>
  <v-dialog
    v-model="dialogStatus"
    content-class="dialog-fullscreen hide-scrollbar"
    fullscreen
    hide-overlay
    transition="dialog-top-transition"
    persistent
  >
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>{{ $t("module.create") }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card>
      <v-container v-if="typeSelected">
        <v-row>
          <v-col cols="12" sm="0" md="6">
            <ModulePreview
              v-if="type != ModuleTypes.MAP && styleMetas"
              :type="type"
              :title="form.title"
              :content="form.content"
              :deveui="form.deveui"
              :meta="form.metas"
              :tagKey="form.tagKey"
            />
            <style-picker-module
              :type="type"
              :metas="form.metas"
              v-on:update="updateStyleMeta"
              v-if="form.metas && hasStyle"
            />
          </v-col>

          <v-col cols="12" sm="12" :md="hasStyle || hasPreview ? 6 : 12">
            <module-form
              :headline="$t('module.create')"
              :edit="false"
              :type="type"
              :module="module"
              :styleMetas="styleMetas"
              v-on:update-form="updateFrom"
              v-on:close-dialog-event="close"
              v-on:update-message-event="(val) => $emit('update-message', val)"
              v-on:created-event="createdEventHandler"
              v-on:change-type-event="typeSelected = false"
            />
          </v-col>
        </v-row>
        <v-row v-if="type == ModuleTypes.CONTROLSLIDERS">
          <v-col>
            <control-slider-picker :metas="module.meta" v-on:update="setMeta" />
          </v-col>
        </v-row>
      </v-container>
      <v-col sm="12" md="12" align="center" v-else>
        <ModuleTypeSelector v-on:update="selectType" />
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ModuleTypeSelector from "@/components/tag/ModuleTypeSelector";
import ModulePreview from "@/components/common/ModulePreview";
import StylePickerModule from "@/components/common/StylePickerModule";
import ControlSliderPicker from "@/components/common/ControlSliderPicker";
import ModuleTypes from "@/_helpers/ModuleTypeHelper.js";
import ModuleForm from "@/components/module/ModuleForm.vue";

export default {
  name: "ModuleCreate",

  props: ["dialog"],

  mixins: [ModuleTypes],

  data() {
    return {
      valid: false,
      typeSelected: false,

      meta: {
        key: null,
        valueMatch: null,
        value: null,
        deveui: null,
      },

      form: {},
      module: {},

      syncChart: false,
      styleMetas: [],
      metas: [],
      type: null,

      moduleTypes: ModuleTypes.types,
    };
  },

  methods: {
    ...mapActions("tag", ["getTags", "getKeysForTag"]),
    ...mapActions("tagData", { getTdKeys: "getTagDataKeys" }),
    ...mapActions("dashboards", ["getDashboards"]),

    close() {
      this.typeSelected = false;
      if (this.$refs.moduleForm) this.$refs.moduleForm.reset();
      this.$emit("close-dialog");
    },

    createdEventHandler() {
      this.form = {};
      this.typeSelected = false;
    },

    updateFrom(val) {
      this.form = val;
    },

    updateStyleMeta(styleMetas) {
      // Set stylemeta reactively
      this.styleMetas = styleMetas.slice(0, styleMetas.length);

      if (this.styleMetas) {
        this.form.metas = this.form.metas.filter(
          (m) => m.key != null && !m.key.startsWith("so_style_meta")
        );

        if (this.styleMetas.length > 0)
          this.form.metas = this.form.metas.concat(this.styleMetas);
      }
    },

    setMeta(meta) {
      if (meta && meta.length > 0) {
        this.form.metas = meta;
      }
    },

    selectType(type) {
      this.metas = [];
      this.styleMetas = [];
      this.type = type;
      this.typeSelected = true;
    },
  },

  computed: {
    ...mapState("tag", { tagStatus: "status", tags: "tags" }),
    ...mapState("tagData", { tagDataStatus: "status", keys: "keys" }),
    ...mapState("dashboards", ["dashboards", "currentDashboard"]),
    ...mapState("modules", { currentModule: "currentModule" }),
    ...mapState("scripts", { scriptStatus: "status", scripts: "scripts" }),

    dialogStatus() {
      return this.dialog;
    },

    hasPreview() {
      let moduleType = this.moduleTypes.find((e) => e.value == this.type);

      if (!moduleType) return null;

      if (moduleType.hasPreview) return true;

      return false;
    },

    hasStyle() {
      let moduleType = this.moduleTypes.find((e) => e.value == this.type);

      if (!moduleType) return null;

      if (
        moduleType.hasOwnStyle ||
        moduleType.colorFieldsUsed.find((e) => e == true)
      )
        return true;

      return false;
    },
  },

  created() {},

  watch: {
    // eslint-disable-next-line
    async deveui(val, oldVal) {
      if (val != oldVal) {
        await this.getKeysForTag({ tagId: val });
        if (oldVal) this.metas = [];
      }
    },

    dialog(val) {
      if (val) {
        if (
          this.deveui !== null &&
          this.deveui !== undefined &&
          this.deveui !== ""
        ) {
          this.getKeysForTag({ tagId: this.deveui });
        } else {
          this.getTdKeys();
        }
      }
    },
  },

  components: {
    ModuleTypeSelector,
    ModulePreview,
    StylePickerModule,
    ModuleForm,
    ControlSliderPicker,
  },
};
</script>

<style>
.preserve-spacing {
  white-space: pre-wrap;
}

.dialog-fullscreen {
  max-width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
</style>
