<template>
  <v-card
    :style="
      'width: 100% !important; height: 100% !important; max-height: 100%; overflow-y: scroll; overflow: hidden;' +
      (soMetaBackgroundColorCard
        ? 'background-color: ' + soMetaBackgroundColorCard
        : '')
    "
  >
    <v-toolbar dense flat style="background: transparent">
      <v-toolbar-title
        :style="soMetaTextColorTitle ? 'color: ' + soMetaTextColorTitle : ''"
        >{{ module.title }}</v-toolbar-title
      >
    </v-toolbar>
    <vue-friendly-iframe
      :src="src"
      style="max-height: 100%"
    ></vue-friendly-iframe>
  </v-card>
</template>

<script>
import Meta from "@/_helpers/ModuleMeta";

export default {
  name: "ModIFrame",

  mixins: [Meta],

  props: ["module", "src", "fakeData"],

  data() {
    return {};
  },

  watch: {},
};
</script>

<style>
.vue-friendly-iframe iframe {
  width: 100%;
  height: 100%;
  background: #fff;
}

.vue-friendly-iframe {
  width: 100%;
  height: 100%;
  min-height: 400px;
}
</style>
