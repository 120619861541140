<template>
  <v-card
    :style="
      'width: 100% !important; height: 100%; overflow: hidden;' +
      (soMetaBackgroundColorCard
        ? 'background-color: ' + soMetaBackgroundColorCard
        : '')
    "
  >
    <v-row style="height: 100%; margin: 0px">
      <v-toolbar
        v-if="!titleHidden"
        dense
        flat
        :style="titleColor ? titleColor : 'background: transparent;'"
      >
        <v-toolbar-title
          :style="soMetaTextColorTitle ? 'color: ' + soMetaTextColorTitle : ''"
          >{{ module.title }}</v-toolbar-title
        >
      </v-toolbar>
      <v-container class="pa-0 ma-0 align-center justify-center">
        <v-row class="pa-0 ma-0 justify-center" style="height: 100%">
          <v-card-text class="pa-2" v-if="!infoHidden">{{
            getDescription + (targetName === null ? "N/A" : targetName)
          }}</v-card-text>
          <v-card-actions
            v-if="functionType != types.ONOFFSWITCH"
            :class="!infoHidden ? 'pt-0 mt-0' : ''"
          >
            <v-btn
              :class="!infoHidden ? 'mb-5' : ''"
              style="margin: 0 auto; min-width: 10vh"
              color="primary"
              @click="buttonClick()"
              :disabled="buttonDisabled"
            >
              {{
                buttonLabel && 30 > buttonLabel.length
                  ? buttonLabel
                  : functionType == types.VALUEINPUT
                  ? $t("common.create")
                  : $t("common.run")
              }}
            </v-btn>
          </v-card-actions>
          <v-card-actions
            v-else
            :class="!infoHidden ? 'pt-0 mt-0 pb-5' : 'pb-2'"
          >
            <v-row class="pa-0 my-0 justify-center" style="overflow: hidden">
              <v-btn
                style="min-width: 8vh"
                color="success"
                @click="buttonOnoff(1)"
                :disabled="buttonDisabled || currentValue == 1"
              >
                {{
                  buttonLabel && buttonLabel.on != null
                    ? buttonLabel.on
                    : $t("common.on")
                }}
              </v-btn>
              <div class="px-1 py-5"></div>
              <v-btn
                style="min-width: 8vh"
                color="error"
                @click="buttonOnoff(0)"
                :disabled="buttonDisabled || currentValue == 0"
              >
                {{
                  buttonLabel && buttonLabel.off != null
                    ? buttonLabel.off
                    : $t("common.off")
                }}
              </v-btn>
              <div class="px-1"></div>
            </v-row>
          </v-card-actions>
        </v-row>
      </v-container>
      <v-dialog v-model="dialog" max-width="400px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t("common.run") }}</span>
          </v-card-title>
          <v-card-text>
            {{ $t("common.verify") }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              {{ $t("common.no") }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="submit()">
              {{ $t("common.yes") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="valueDialog" max-width="400px">
        <v-card>
          <v-card-title>
            <span class="headline">{{
              $t("module.functionButtonTypes.input")
            }}</span>
          </v-card-title>
          <v-text-field
            class="mx-5 mt-3"
            v-model="inputValue"
            :label="$t('common.newValue')"
            mx-5
            outlined
          />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="valueDialog = false">
              {{ $t("common.cancel") }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="submit()">
              {{ $t("common.submit") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Meta from "@/_helpers/ModuleMeta";
import i18n from "@/plugins/i18n.js";
import CsharpEnum from "@/_helpers/CsharpEnum";

export default {
  name: "ModFunctionButton",

  mixins: [Meta],

  props: ["module", "fakeData"],

  data() {
    return {
      titleColor: null,
      targetId: null,
      targetName: "",
      functionType: null,
      dialog: false,
      valueDialog: false,
      inputValue: "",
      buttonLabel: null,
      titleHidden: false,
      infoHidden: false,
      buttonDisabled: false,
      buttonTimeout: null,
      downlinkData: { payload: "", port: 1, confirmed: false },
      currentValue: null,

      functionButtonTypes: [
        {
          name: i18n.t("module.functionButtonTypes.script"),
          value: CsharpEnum.FunctionTypes.SCRIPT,
        },
        {
          name: i18n.t("module.functionButtonTypes.input"),
          value: CsharpEnum.FunctionTypes.VALUEINPUT,
        },
        {
          name: i18n.t("module.functionButtonTypes.navigation"),
          value: CsharpEnum.FunctionTypes.DASHBOARD,
        },
      ],
    };
  },

  methods: {
    ...mapActions("scripts", { getScript: "getScript", runScript: "execute" }),
    ...mapActions("dashboards", ["getDashboard"]),
    ...mapActions("tag", ["setTagData"]),
    ...mapActions("tagData", ["getCurrentTagdata"]),
    ...mapActions("downlinks", {
      createDownlink: "createDownlink",
    }),

    async buttonClick() {
      if (this.functionType == this.types.DASHBOARD) {
        this.$router.push("/dashboard/" + this.targetId);
        this.$router.go();
      } else if (this.functionType == this.types.VALUEINPUT)
        this.valueDialog = true;
      else this.dialog = true;
    },

    async buttonOnoff(status) {
      if (this.module.tag != null) {
        let payload = { key: this.module.key, value: status };
        await this.setTagData({
          tagId: this.module.tag.deveui,
          payload: payload,
        });
      }
      this.currentValue = status;

      // Disable the button for 5 seconds to prevent spam
      this.buttonDisabled = true;
      this.buttonTimeout = setTimeout(
        () => (this.buttonDisabled = false),
        5000
      );
    },

    async submit() {
      this.dialog = false;

      if (this.functionType == this.types.SCRIPT && this.targetId != null)
        await this.runScript({ scriptId: this.targetId });

      if (this.functionType == this.types.VALUEINPUT) {
        let payload = { key: this.module.key, value: this.inputValue };
        await this.setTagData({
          tagId: this.module.tag.deveui,
          payload: payload,
        });

        this.valueDialog = false;
        this.inputValue = "";
      }

      if (this.functionType == this.types.DOWNLINK) {
        await this.createDownlink({
          deveui: this.module.tag.deveui,
          payload: this.downlinkData,
        });
      }

      // Disable the button for 5 seconds to prevent spam
      this.buttonDisabled = true;
      this.buttonTimeout = setTimeout(
        () => (this.buttonDisabled = false),
        5000
      );
    },

    updateColors() {
      if (!this.module.meta) return;

      let styleMetas = this.module.meta.filter((m) =>
        m.key.startsWith("so_style_meta")
      );
      for (let meta of styleMetas) {
        if (meta.key == "so_style_meta_color_background_title") {
          this.titleColor = "background-color: " + meta.content;
        } else if (meta.key == "so_style_meta_color" && !this.titleColor) {
          this.titleColor = "background-color: " + meta.content;
        }
      }
    },

    async checkType() {
      let script = await this.getMeta(this.module.meta, Meta.Enum.SCRIPTID);
      if (script) {
        this.functionType = this.types.SCRIPT;
        this.targetId = script.value;

        await this.getScript({ scriptId: script.value });
        this.targetName = this.currentScript.name;
        return;
      }

      let dashboard = await this.getMeta(
        this.module.meta,
        Meta.Enum.DASHBOARDID
      );
      if (dashboard) {
        this.functionType = this.types.DASHBOARD;
        this.targetId = dashboard.value;

        await this.getDashboard({ dashboardId: dashboard.value });
        this.targetName = this.currentDashboard.name;
        return;
      }

      let onoffSwitch = await this.getMeta(
        this.module.meta,
        Meta.Enum.ONOFFSWITCH
      );
      if (onoffSwitch) {
        this.functionType = this.types.ONOFFSWITCH;

        if (this.module.tag != null) {
          this.targetName = this.module.tag.deveui + " - " + this.module.key;
          await this.getCurrentTagdata({
            tagId: this.module.tag.deveui,
            tagKey: this.module.key,
          });
          this.currentValue = this.currentTagData
            ? this.currentTagData.value
            : null;
        }
        return;
      }

      if (this.module.tag && this.module.key) {
        this.functionType = this.types.VALUEINPUT;
        this.targetName = this.module.tag.deveui + " - " + this.module.key;
      }

      let downlinkMeta = this.getMeta(this.module.meta, Meta.Enum.DOWNLINK);

      if (downlinkMeta != null) {
        this.functionType = this.types.DOWNLINK;
        this.downlinkData = {
          payload: downlinkMeta.value,
          port: downlinkMeta.valueMatch,
          confirmed: downlinkMeta.content,
        };
      }
    },
  },
  computed: {
    ...mapState("scripts", ["currentScript"]),
    ...mapState("dashboards", ["currentDashboard"]),
    ...mapState("tagData", ["currentTagData"]),

    getDescription() {
      if (this.functionType == this.types.DASHBOARD)
        return i18n.t("common.dashboard") + ": ";
      else if (this.functionType == this.types.SCRIPT)
        return i18n.t("script.name") + ": ";

      return "";
    },

    types() {
      return CsharpEnum.FunctionTypes;
    },
  },

  async created() {
    this.updateColors();
    await this.checkType();

    if (this.functionType == this.types.ONOFFSWITCH) {
      this.buttonLabel = {};
      let buttonOnLabelMeta = await this.getMeta(
        this.module.meta,
        Meta.Enum.BUTTONLABEL_ON
      );
      this.buttonLabel.on = buttonOnLabelMeta ? buttonOnLabelMeta.value : null;

      let buttonOffLabelMeta = await this.getMeta(
        this.module.meta,
        Meta.Enum.BUTTONLABEL_OFF
      );
      this.buttonLabel.off = buttonOffLabelMeta
        ? buttonOffLabelMeta.value
        : null;
    } else {
      let buttonLabelMeta = await this.getMeta(
        this.module.meta,
        Meta.Enum.BUTTONLABEL
      );
      this.buttonLabel = buttonLabelMeta ? buttonLabelMeta.value : null;
    }

    let hideTitleMeta = await this.getMeta(
      this.module.meta,
      Meta.Enum.HIDETITLE
    );
    this.titleHidden = hideTitleMeta ? hideTitleMeta.value : false;

    let hideInfoMeta = await this.getMeta(
      this.module.meta,
      Meta.Enum.SINGLEHIDENODEINFO
    );
    this.infoHidden = hideInfoMeta ? hideInfoMeta.value : false;
  },

  beforeDestroy() {
    this.buttonDisabled = false;
    clearTimeout(this.buttonTimeout);
  },

  watch: {
    module() {
      if (this.fakeData && this.module.meta && this.module.meta.length > 0) {
        this.updateColors();
      }
    },
  },
};
</script>

<style></style>
