<template>
  <v-row align="center" style="margin: 0 1rem; margin-bottom: 3rem">
    <v-col
      sm="2"
      md="4"
      v-for="type in moduleTypes"
      v-bind:key="type.name"
      @click="selectType(type.value)"
    >
      <v-container class="type-border">
        <v-icon color="accent" size="100" style="width: 100%; margin: 0 auto">{{
          type.icon
        }}</v-icon>
        <h4 class="text-center">{{ type.name }}</h4>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import ModuleTypes from "@/_helpers/ModuleTypeHelper.js";
export default {
  name: "ModuleTypeSelector",

  data() {
    return {
      moduleTypes: ModuleTypes.types,
    };
  },

  methods: {
    selectType(type) {
      this.$emit("update", type);
    },
  },
};
</script>

<style>
.type-border {
  padding: 0rem;
}

.type-border:hover {
  cursor: pointer;
}
</style>