<template>
  <v-card
    :style="
      'height: 100%; overflow: hidden;' +
      (soMetaBackgroundColorCard
        ? 'background-color: ' + soMetaBackgroundColorCard
        : '')
    "
  >
    <ChartControl
      v-on:update-ranges="newData"
      :enabled="settingsEnabled"
      :loading="loading"
      :module="module"
      :titleTextColor="soMetaTextColorTitle"
    />
    <v-row no-gutters style="height: 75%">
      <e-chart
        v-if="opts.series.length > 0"
        :option="opts"
        :style="
          fakeData ? 'width: 100%; height: 300px' : 'width: 100%; height: 100%'
        "
        :autoresize="true"
        :theme="this.darkmode ? 'sensoronlinedark' : 'macarons'"
      />
    </v-row>
    <v-row no-gutters style="height: 5%">
      <span
        style="text-align: center; color: #999; width: 100%"
        v-if="resolution"
      >
        {{ displayResolution }}
      </span>
    </v-row>
  </v-card>
</template>

<script>
import ChartControl from "@/components/chart/ChartControl";
import { mapActions, mapState } from "vuex";
import "echarts/lib/coord/cartesian/Grid";

import i18n from "@/plugins/i18n";
import Meta from "@/_helpers/ModuleMeta";

export default {
  name: "TrendChart",

  mixins: [Meta],

  props: {
    settingsEnabled: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
    },
    module: {
      default: {},
    },
    tag: {
      default: null,
    },
    timerTick: {
      default: null,
    },
    fakeData: {
      default: false,
    },
  },

  data() {
    return {
      startDate: new Date(new Date().setHours(new Date().getHours() - 24)),
      endDate: new Date(),
      opts: {
        legend: {
          show: true,
          data: [],
        },
        grid: {
          top: 30,
          bottom: 35,
          left: 60,
          right: 60,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        xAxis: {
          type: "time",
          splitLine: {
            show: true,
          },
          axisLine: {
            lineStyle: {
              width: 2,
            },
          },
        },
        yAxis: {
          type: "value",
        },
        dataZoom: [
          {
            show: true,
            type: "inside",
            throttle: 50,
          },
        ],
        series: [],
      },
      resolution: null,
    };
  },

  computed: {
    ...mapState("configuration", ["darkmode"]),

    displayResolution() {
      let tr = "";
      if (this.resolution > 24) {
        tr = this.$t("trend.res.week");
      } else if (this.resolution > 10) {
        tr = this.$t("trend.res.day");
      } else if (this.resolution > 1 && this.resolution < 10) {
        tr = this.$t("trend.res.five");
      } else if (this.resolution <= 1) {
        tr = this.$t("trend.res.hour");
      }

      return tr;
    },
  },

  methods: {
    ...mapActions("tagData", ["getTrendDataForTag"]),

    async updateData() {
      this.startDate = new Date(this.startDate - (this.endDate - new Date()));
      this.endDate = new Date();

      let data = await this.getTrendDataForTag({
        tagId: this.module.tag.deveui,
        tagKey: this.module.key,
        metrics: {
          from: this.startDate.toISOString(),
          to: this.endDate.toISOString(),
        },
      });

      if (data != undefined && data.legend > 0) {
        data.forEach((d) =>
          this.setData(
            this.module.tag.name + " - " + d.key,
            d.value,
            new Date(d.date)
          )
        );

        if (data.length > 2)
          this.resolution = this.getDiff(data[0].date, data[1].date);
      }
    },

    getDiff(d1, d2) {
      if (!d1 || !d2) return null;
      let diff = (new Date(d2).getTime() - new Date(d1).getTime()) / 1000;
      diff /= 60 * 60;
      return Math.abs(Math.round(diff));
    },

    async newData(metrics) {
      if (metrics == undefined) return;

      // Fiddle with date and time to fetch correct data
      this.startDate = new Date(metrics.from)
        .zeroTime()
        .addHours(1)
        .toISOString();
      this.endDate = new Date(metrics.to);

      let data = await this.getTrendDataForTag({
        tagId: this.module.tag.deveui,
        tagKey: this.module.key,
        metrics: {
          from: new Date(metrics.from).zeroTime().addHours(1).toISOString(),
          to: new Date(metrics.to).toISOString(),
        },
      });

      if (data != undefined && data.length > 0) {
        this.opts.series = [];
        this.opts.legend.data = [];
        data.forEach((d) =>
          this.setData(
            this.module.tag.name + " - " + d.key,
            d.value,
            new Date(d.date)
          )
        );

        if (data.length > 2)
          this.resolution = this.getDiff(data[0].date, data[1].date);
      }
    },

    setData(name, value, date) {
      if (!this.opts.series.map((d) => d.name).includes(name))
        this.opts.series.push({
          name: name,
          type: "bar",
          data: [],
          itemStyle: {},
          label: {
            show: true,
            position: "top",
            color: "#999",
          },
        });

      // Check if fieldname exist in series
      for (var i in this.opts.series) {
        if (this.opts.series[i].name == name) {
          if (!this.opts.legend.data.includes(name))
            this.opts.legend.data.push(name);

          this.opts.series[i].data.push({
            name: new Date(date).toString(),
            value: [date, +parseFloat(value).toFixed(2)],
          });
          this.opts.series[i].barMaxWidth = 50;
          return;
        }
      }
    },

    setFakeData(name) {
      this.opts.series = [];
      this.opts.legend.data = [];
      var base = +new Date(1988, 9, 3);
      var oneDay = 24 * 3600 * 1000;
      var lastVal = Math.random(0, 20);

      for (var i = 1; i < 10; i++) {
        var now = new Date((base += oneDay));
        let d = [now.getFullYear(), now.getMonth() + 1, now.getDate()].join(
          "/"
        );
        let val = Math.round((Math.random() - 0.1) * 60 + lastVal);
        this.setData(name, val, d);
        lastVal = val;
      }
    },

    updateColors() {
      if (!this.module.meta) return;

      let styleMetas = this.module.meta.filter((m) =>
        m.key.startsWith("so_style_meta")
      );
      for (let meta of styleMetas) {
        if (meta.key == "so_style_meta_color") {
          this.opts.series[0].itemStyle.color = meta.content;
        }
      }
    },

    async setMetaParams() {
      let timeSpanMeta = await this.getMeta(
        this.module.meta,
        Meta.Enum.DEFAULT_TIMESPAN
      );

      if (timeSpanMeta) {
        switch (timeSpanMeta.value) {
          case "30_days":
            this.startDate = new Date(new Date() - new Date(0).setUTCDate(30));
            break;
          case "7_days":
            this.startDate = new Date(new Date() - new Date(0).setUTCDate(7));
            break;
          case "24_hour":
            this.startDate = new Date(new Date() - new Date(0).setUTCHours(24));
            break;
          case "5_hour":
            this.startDate = new Date(new Date() - new Date(0).setUTCHours(5));
            break;
          case "hour":
            this.startDate = new Date(new Date() - new Date(0).setUTCHours(1));
            break;
        }
      }
    },
  },

  async created() {
    if (!this.fakeData) {
      let storedTimeOffset = this.getModuleSettingsTimeOffset(
        this.module.dashboardModuleId
      );

      if (storedTimeOffset != undefined) {
        this.startDate = new Date(new Date() - storedTimeOffset);
        this.endDate = new Date();
      }

      await this.setMetaParams();

      let data = await this.getTrendDataForTag({
        tagId: this.module.tag.deveui,
        tagKey: this.module.key,
        metrics: {
          from: this.startDate.toISOString(),
          to: this.endDate.toISOString(),
        },
      });

      if (data != undefined && data.length > 0) {
        data.forEach((d) =>
          this.setData(
            this.module.tag.name + " - " + d.key,
            d.value,
            new Date(d.date)
          )
        );

        if (data.length > 2)
          this.resolution = this.getDiff(data[0].date, data[1].date);
      }
    } else {
      let n = this.module.tag
        ? this.module.tag.name
        : i18n.t("module.meta.fields.deveui") + " 1";
      let k = this.module.key
        ? this.module.key
        : i18n.t("module.meta.fields.tagkey") + " 1";
      this.setFakeData(n + " - " + k);
    }
    this.updateColors();
  },

  components: {
    ChartControl,
  },

  watch: {
    timerTick(v) {
      if (v % 12 == 0) {
        if (this.timeWithinHours(this.endDate, 1)) this.updateData();
      }
    },

    module() {
      if (this.fakeData && this.module.meta && this.module.meta.length > 0) {
        this.setFakeData(
          (this.module.tag
            ? this.module.tag.name
            : i18n.t("module.meta.fields.deveui") + " 1") +
            " - " +
            (this.module.key
              ? this.module.key
              : i18n.t("module.meta.fields.tagkey") + " 1")
        );

        this.updateColors();
      }
    },
  },
};
</script>